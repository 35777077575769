import Vue from 'vue';
// highcharts imports
import Highcharts from 'highcharts';
import Maps from 'highcharts/modules/map';
import HighchartsVue from 'highcharts-vue';
import VueGates from 'vue-gates';

/// bootstrap icons
import { BootstrapVueIcons } from 'bootstrap-vue';
// install plugin
import './plugin/bootstrap';
import './plugin/vue-multiselect';
import './plugin/vee-validate';
import './plugin/axios';
// root App file
import Toast from '@/plugin/customToast/index';

// date and time filters for moment js
import './modules/ngo/config/filter/date-time-filter';

import App from './App.vue';
import './registerServiceWorker';
// dataset api services plugin
import './modules/dataset/plugins/api';

import router from './router';
import store from './store';
// global scss file
import './scss/main.scss';

// registering  Components globally
import '@/components/FormInputs/index';
import '@/components/Layouts/index';

import '@/modules/auth';

Vue.use(BootstrapVueIcons);
Vue.use(Toast);
Vue.use(VueGates);

// Vue.use(VueHighcharts, { Highcharts });
Maps(Highcharts);
Vue.use(HighchartsVue);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
