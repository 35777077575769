<template>
  <div :class="[showsideborder ? 'border-left' : '', 'text-left p-3 h-100']">
    <div>
      <div class="d-flex">
        <p class="font-weight-bolder h6 text-black-50 mb-4 mt-n2">FILTERS</p>
        <v-spacer />
        <div
          class="filter-button mt-n2"
          :style="
            `position: relative;
  left: ${filterButtonAlign};`
          "
        >
          <base-button text="Apply Filter" @click="applyFilter" :loading="loading" />
        </div>
      </div>
      <p class="font-weight-bolder pb-2 h6 text-black-50">PROGRAM AREAS</p>
      <div
        v-if="programsAreaToShowComputed.length > 0"
        :class="programsAreaToShowComputed.length > 3 ? 'states' : ''"
      >
        <base-checkbox
          v-for="(program, index) in programsAreaToShowComputed"
          :key="index"
          class="pl-2 mt-n5"
          v-model="progamAreasSelected"
          :checkboxItem="program.name"
          :item="program"
        />
      </div>
      <p
        v-else
        style="
        font-size: 15px; color: #888888"
      >
        No program area data found
      </p>
      <b-link
        v-if="programsAreaToShowComputed.length < 4 && !programsAreaToShowComputed.length < 1"
        class="text-decoration-none"
        @click="showMoreLessProgramArea(true)"
        >See {{ getProgramsArea.results.length - 3 }} more</b-link
      >
      <b-link
        v-if="programsAreaToShowComputed.length > 4 && !programsAreaToShowComputed.length < 1"
        class="text-decoration-none"
        @click="showMoreLessProgramArea(false)"
        >See less</b-link
      >
    </div>

    <div>
      <p class="font-weight-bolder regular mb-n4 mt-4 pb-2 h6 text-black-50">STATES</p>
      <base-input
        @input="searchStates"
        v-model="stateToSearch"
        prepend="fa fa-search"
        height="1.8rem"
        prependclass="mt-n1"
        :filled="true"
        class="pb-2"
        placeholder="Search"
        :width="statesSearchWidth ? statesSearchWidth : '90%'"
      />
      <div
        v-if="statesToShowComputed.length > 0"
        :class="statesToShowComputed.length > 4 ? 'states' : ''"
      >
        <base-checkbox
          v-for="(state, index) in statesToShowComputed"
          :key="index"
          class="pl-2 mt-n5"
          :checkboxItem="state.name"
          :item="state"
          v-model="stateSelected"
        />
      </div>
      <p
        v-else
        style="
        font-size: 15px; color: #888888"
      >
        No State found for your search "{{ stateToSearch }}"
      </p>
      <b-link
        v-if="statesToShowComputed.length < 4 && !statesToShowComputed.length < 1"
        class="text-decoration-none"
        @click="showMoreLessStates(true)"
        >See {{ states.length - 4 }} more</b-link
      >
      <b-link
        v-if="statesToShowComputed.length > 4 && !statesToShowComputed.length < 1"
        class="text-decoration-none"
        @click="showMoreLessStates(false)"
        >See less</b-link
      >
    </div>

    <div class="w-100">
      <p class="font-weight-bolder mt-4 pb-2 h6 text-black-50">STATUS OF IMPLEMENTATION</p>
      <base-checkbox
        v-for="(status, index) in ['Completed', 'Ongoing', 'Abadoned']"
        :key="index"
        class="pl-2 mt-n5"
        :checkboxItem="status"
        :item="status"
        v-model="statusSelected"
      />
    </div>

    <div>
      <p class="font-weight-bolder mt-4 pb-2 h6 text-black-50">HEALTH SYSTEM AREA</p>
      <div
        v-if="healthSystemAreaToShowComputed.length > 0"
        :class="healthSystemAreaToShowComputed.length > 4 ? 'states' : ''"
      >
        <base-checkbox
          v-for="(healthsystem, index) in healthSystemAreaToShowComputed"
          :key="index"
          class="pl-2 mt-n5"
          :checkboxItem="healthsystem.name"
          :item="healthsystem"
          v-model="healthSystemAreasSelected"
        />
      </div>
      <p
        v-else
        style="
        font-size: 15px; color: #888888"
      >
        No health system data found
      </p>
      <b-link
        v-if="
          healthSystemAreaToShowComputed.length < 4 && !healthSystemAreaToShowComputed.length < 1
        "
        class="text-decoration-none"
        @click="showMoreLessHealthSysytem(true)"
        >See {{ healthSystemAreaFormatted.length - 3 }} more</b-link
      >
      <b-link
        v-if="
          healthSystemAreaToShowComputed.length > 4 && !healthSystemAreaToShowComputed.length < 1
        "
        class="text-decoration-none"
        @click="showMoreLessHealthSysytem(false)"
        >See less</b-link
      >
    </div>

    <div v-if="accessLevel">
      <p class="font-weight-bolder mt-4 pb-2 h6 text-black-50">ACCESS LEVEL</p>
      <base-checkbox
        class="pl-2 mt-n5"
        v-for="(access, index) in sensitivity"
        :key="index"
        :checkboxItem="access.name"
        :item="access"
        v-model="accessLevelSelected"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import baseButton from '../../../../components/FormInputs/_base-button.vue';

export default {
  components: { baseButton },
  props: {
    showsideborder: {
      type: Boolean,
      default: true,
    },
    statesSearchWidth: {
      type: String,
      default: null,
    },
    filterButtonAlign: {
      type: String,
      default: '7rem',
    },
    accessLevel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      stateToSearch: '',
      statesToShow: [],
      progamAreasSelected: [],
      programsAreaSelectedToSend: [],
      stateSelected: [],
      stateToSend: [],
      accessLevelSelected: [],
      accessLevelToSend: [],
      statusSelected: [],
      statusToSend: [],
      healthSystemAreasSelected: [],
      healthSystemAreasToSend: [],
      sensitivity: [],
      states: [],
      healthSystemAreaToShow: [],
      healthSystemAreaFormatted: [],
      programsAreaToShow: [],
      programsAreaFormatted: [],
    };
  },
  methods: {
    ...mapActions('DATASET_STORE', ['SETPROGRAMAREAS', 'SETSYSTEMAREA']),

    applyFilter() {
      let filterObj = {};
      filterObj = {
        states: this.stateToSend,
        program_areas: this.programsAreaSelectedToSend,
        health_system_areas: this.healthSystemAreasToSend,
        project_sensitivity: this.accessLevelToSend,
        status_of_implementation: this.statusToSend,
      };

      this.$emit('filterObjs', filterObj);
    },
    searchStates() {
      // const itemSearch = this.stateToSearch;
      // eslint-disable-next-line arrow-body-style
      this.statesToShow = this.states.filter((item) => {
        return item.name.toLowerCase().includes(this.stateToSearch.toLowerCase());
      });
    },
    showMoreLessHealthSysytem(show) {
      if (show) {
        this.healthSystemAreaToShow = this.getHealthSystemArea.results;
        return;
      }
      this.healthSystemAreaToShow = this.healthSystemAreaFormatted.slice(0, 3);
    },
    showMoreLessProgramArea(show) {
      if (show) {
        this.programsAreaToShow = this.getProgramsArea.results;
        return;
      }

      this.programsAreaToShow = this.programsAreaFormatted.slice(0, 3);
    },
    showMoreLessStates(show) {
      if (show) {
        this.statesToShow = this.states;
        return;
      }
      this.statesToShow = this.states.slice(0, 3);
    },
  },
  watch: {
    getHealthSystemArea(val) {
      this.healthSystemAreaFormatted = val;
      this.healthSystemAreaToShow = val.slice(0, 3);
    },
    getProgramsArea(val) {
      this.programsAreaFormatted = val;
      this.programsAreaToShow = val.slice(0, 3);
    },

    progamAreasSelected(val) {
      const indexOfItem = this.programsAreaSelectedToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.programsAreaSelectedToSend.splice(indexOfItem, 1);
        return;
      }
      this.programsAreaSelectedToSend.push(Number(val.item.id));
    },
    stateSelected(val) {
      const indexOfItem = this.stateToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.stateToSend.splice(indexOfItem, 1);
        return;
      }
      this.stateToSend.push(Number(val.item.id));
    },
    accessLevelSelected(val) {
      const indexOfItem = this.accessLevelToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.accessLevelToSend.splice(indexOfItem, 1);
        return;
      }
      this.accessLevelToSend.push(Number(val.item.id));
    },
    statusSelected(val) {
      const indexOfItem = this.statusToSend.indexOf(val.item);
      if (indexOfItem >= 0) {
        this.statusToSend.splice(indexOfItem, 1);
        return;
      }
      this.statusToSend.push(val.item);
    },
    healthSystemAreasSelected(val) {
      const indexOfItem = this.healthSystemAreasToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.healthSystemAreasToSend.splice(indexOfItem, 1);
        return;
      }
      this.healthSystemAreasToSend.push(Number(val.item.id));
    },
  },
  async mounted() {
    if (this.getProgramsArea.length < 1 || this.getHealthSystemArea.length < 1) {
      const programsAreaData = await this.$dataset.getAllProgramAreas();
      const healthAreas = await this.$dataset.getAllHealthAreas();

      await this.SETPROGRAMAREAS(programsAreaData.data);
      await this.SETSYSTEMAREA(healthAreas.data);
    }
    const statesData = await this.$dataset.getStates();
    const sensitivityData = await this.$dataset.getAllSensitivity();
    console.log(sensitivityData);
    this.sensitivity = sensitivityData.data.results;
    this.states = statesData.data.results;
    this.statesToShow = statesData.data.results.slice(0, 3);
    this.healthSystemAreaFormatted = this.getHealthSystemArea.results;
    this.healthSystemAreaToShow = this.healthSystemAreaFormatted.slice(0, 3);
    this.programsAreaFormatted = this.getProgramsArea.results;
    this.programsAreaToShow = this.programsAreaFormatted.slice(0, 3);
  },
  computed: {
    ...mapGetters('DATASET_STORE', ['getProgramsArea', 'getHealthSystemArea']),
    statesToShowComputed() {
      return this.statesToShow;
    },
    healthSystemAreaToShowComputed() {
      return this.healthSystemAreaToShow;
    },
    programsAreaToShowComputed() {
      return this.programsAreaToShow;
    },
  },
};
</script>

<style scoped>
.states {
  height: 10rem;
  overflow: auto;
}
</style>
