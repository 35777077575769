<template>
  <div>
    <b-container>
      <b-row class="mb-4 ml-1">
        <h4 class="text-left text-primary ml-n1">{{ paramName }}</h4>
      </b-row>
      <div v-if="loading" class="text-center loading-icon">
        <b-spinner v-if="loading" x-large></b-spinner>
      </div>
      <div v-else class="mb-n2 mt-5">
        <ProjectCard
          v-for="(item, index) in ComputedProjects"
          :key="index"
          :to="`/dataset/stakeholder/project/${item.id}`"
          :date="item.created_at"
        >
          <span> {{ item.stake_holder.name }} </span>
          <template slot="body">
            <b-card-title class="text-left font-weight-bold text-black-50 h5">
              {{ item.activity }}
            </b-card-title>

            <div class="text-left mt-4">
              <span class="font-weight-bolder text-black-50">Name of organisation:</span>
              <span class="ml-1 text-black-50">{{ item.stake_holder.name }}</span>
            </div>

            <div class="text-left mt-4">
              <span class="font-weight-bolder text-black-50">Branch:</span>
              <span class="ml-1 text-black-50">{{ item.stake_holder_branch.name }}</span>
            </div>

            <div class="text-left mt-4">
              <span class="font-weight-bolder text-black-50">Category:</span>
              <span class="ml-1 text-black-50">{{ item.stake_holder_category.name }}</span>
            </div>

            <div class="text-left mt-4">
              <span class="font-weight-bolder text-black-50">Role:</span>
              <span class="ml-1 text-black-50">{{ item.role }}</span>
            </div>
          </template>
        </ProjectCard>
        <div class="mx-auto text-center">
          <!-- <p class="mx-2 mt-2">Rows per page - {{ perPage }} total rows {{ this.items.length }}</p> -->
          <p class="mx-2 mt-2">
            Page {{ currentPage }} of
            {{ Math.ceil(ComputedData.length / perPage) }}
          </p>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <p class="mt-1 mx-2">Row per page</p>
            <select
              id="zone"
              style="width: 50px; height: 40px;"
              v-model="perPage"
              class="mb-3 mr-1"
            >
              <option value="5" selected> 5 </option>
              <option value="10"> 10 </option>
            </select>
            <b-pagination
              v-model="currentPage"
              :total-rows="ComputedData.length"
              :per-page="perPage"
              aria-controls="my-table"
              align="center"
            ></b-pagination>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectCard from '../../../components/Shared/ProjectCard.vue';

export default {
  components: {
    ProjectCard,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      pageNum: 1,
      loading: false,
      categorySelected: {},
      branchSelected: {},
      cards: [],
      total: 0,
      paramName: this.$route.params.name,
    };
  },

  computed: {
    ...mapGetters('DATASET_STORE', ['getStakeholderBranches', 'getStakeholderCategories']),
    ComputedData() {
      return this.cards
        .filter((items) => {
          return items.stake_holder.name === this.paramName;
        })
        .map((rep) => rep);
    },
    ComputedProjects() {
      return this.ComputedData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  async mounted() {
    try {
      this.loading = true;
      const { data } = await this.$dataset.getAllStakeholdersDivisionActivityById(
        `/?stake_holder=${this.$route.params.id}&page_size=1000`
      );
      console.log('main data', data);
      this.cards = data.results;
      this.total = this.cards.results.length;
    } catch (error) {
      this.loading = false;
    }
  },
  methods: {},
};
</script>

<style scoped></style>
