import axios from '@/plugin/axios';
import Routes from './apiroutes';

export const getFeedbackTypes = () => axios.get(Routes.feedback_types);
export const getEventTypes = () => axios.get(Routes.event_types);

export const postFeedback = (payload) => axios.post(Routes.feedback, payload);

export const postEnquiry = (payload) => axios.post(Routes.enquiry, payload);

export const postEvent = (payload) => axios.post(Routes.event, payload);
