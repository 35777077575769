<template>
  <b-container>
    <b-col cols="12" md="9" lg="6" class="mx-auto">
      <b-card
        footer-bg-variant="white"
        style="max-width: auto"
        class="loginshadow mb-3 bg-white text-decoration-none"
      >
        <div class="px-0 px-md-4">
          <b-card-title class="text-center font-weight-bold h5 pt-4">
            Login
          </b-card-title>
          <ValidationObserver v-slot="{ passes }">
            <form @submit.prevent="passes(LoginButton)">
              <base-input
                height="3rem"
                placeholder="Email address"
                width="100%"
                rules="required|email"
                name="Email address"
                v-model="email"
              />
              <base-input
                height="3rem"
                placeholder="Password"
                width="100%"
                :type="password ? 'password' : 'text'"
                rules="required"
                name="Password"
                prependclass="mt-1"
                v-model="password"
              />
              <div class="d-flex justify-content-between pt-2 pb-2">
                <div>
                  <label>
                    <input type="checkbox" v-model="currentValue" value="" />
                    Stay Logged in
                  </label>
                </div>
                <div>
                  <b-link class="text-right text-decoration-none" to="/auth/reset-password">
                    Forgot Password?</b-link
                  >
                </div>
              </div>
              <div>
                <!-- <b-link class="text-right text-decoration-none" to="/dataset/forgot-password">
                  Forgot Password?</b-link
                > -->
              </div>
              <button type="submit" class="sharp" :disabled="loading">
                <b-spinner v-show="loading" small></b-spinner>
                Login
              </button>
            </form>
          </ValidationObserver>
          <!-- <div class="mt-2">
            <span>Don't have an account?</span> <b-link to="/auth/register">Register</b-link>
          </div> -->
        </div>
      </b-card>
    </b-col>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import CustomToast from '../components/CustomToast.vue';

export default {
  data() {
    return {
      password: true,
      email: '',
      loading: false,
      currentValue: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['roles']),
  },
  methods: {
    ...mapActions('auth', [
      'LOGIN',
      'REGISTRATION',
      'GET_USER', // -> this.LOGIN()
    ]),
    async LoginButton() {
      this.loading = true;
      try {
        const data = await this.LOGIN({
          password: this.password,
          email: this.email,
        });
        console.log(data);

        if (data.status === 200) {
          /**
           * set the roles in the Vue gates plugin
           */
          this.$gates.setRoles(this.roles);
          this.$router.push('/ngo');
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.loginshadow {
  box-shadow: 4px 4px 2px -2px rgb(192, 190, 190);
  border: 1px solid #fcfafa;
}

button {
  display: inline-block;
  /* padding: 10px 29px; */
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /* padding: 10px 16px; */
  outline: none;
}

.sharp {
  /* width: 12em; */
  color: #fff;
  background-color: #30ab4c;
  border-color: #0b7022;
}

.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open .dropdown-toggle.sharp {
  /* border-radius: 0; */
  color: #fff;
  background-color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

/***********************
  SHARP BUTTONS
************************/
.sharp {
  outline: none;
  border-radius: 0;
}

/***********************
  CUSTON BTN VALUES
************************/

button {
  padding: 0.7rem;
  outline: none;
  border: 0 none;
  /* font-weight: 700; */
  letter-spacing: 1px;
}
button:focus,
button:active:focus,
button.active:focus {
  outline: 0 none;
}

.sharp {
  background: #30ab4c;
  color: #ffffff;
}
.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open > .dropdown-toggle.sharp {
  background: #30ab4c;
}
.sharp:active,
.sharp.active {
  background: #45cc64;
  box-shadow: none;
}
.save:active,
.save.active {
  background: #d8f7e0;
  color: #30ab4c;
  box-shadow: none;
}
</style>
