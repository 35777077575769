<template>
  <div>
    <h4>NGO Inventory FAQ’s</h4>
    <div class="bg-light p-0 rounded-0">
      <div class="accordion p-0 my-4" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-1
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>How do I register my NGO with the NGO
              branch?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >For registration steps, please visit the “register NGO” page
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-2
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>Why do I need to upload my annual
              reports?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >The NGO branch of the HSS division requires that annual reports be uploaded as
                proof of activity. Once these reports cease, an NGO would be considered
                inactive.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-3
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>How do I upload my reports?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                To upload annual reports, click the “submit annual report” tab on your NGO dashboard
                and fill the form provided.
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-4
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>How do I know if the FMoH has feedback on my
              report?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                If the NGO branch has any feedback for you on your report, a comment will be made in
                the comment section of the report and you will be notified via email.
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-5
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>I can no longer find my NGO on the registered
              NGOs list, why? What do I do?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >If your NGO is no longer on the registered NGO list, it means it has been
                de-registered as a result of an extended period of inactivity. Please contact the
                NGO branch for more information on how to re-register.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-6
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>Who can I contact if I need help?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-6" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Please send us an email, and we will get back to you shortly.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
