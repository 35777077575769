<template>
  <div class="my-5 d-flex justify-content-center">
    <div class="card my-5">
      <div class="card-body">
        <h1 class="font-weight-bold text-primary text-uppercase">
          <span class="font-italic font-weight-light text-dark"></span>
          coming soon
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
