import axios from '@/plugin/axios';
import Endpoints from './routes';

// get requests
export const getFeedType = () => axios.get(Endpoints.feed_types);
export const getEventType = () => axios.get(Endpoints.event_type);

// post requests
export const postFeed = (payload) => {
  const content = payload;
  return axios.post(Endpoints.feed, content);
};

export const postEnq = (payload) => {
  const content = payload;
  return axios.post(Endpoints.enquiry, content);
};
