const fd = '/feedback/crud';

const routes = {
  // `${main}/health_system_area/`

  feedback_types: `${fd}/feedback_type/`,
  event_types: `${fd}/event_type/`,
  feedback: `${fd}/feedback/`,
  enquiry: `${fd}/enquiry/`,
  event: `${fd}/event/`,
};

export default routes;
