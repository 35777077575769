const routes = {
  organization: '/main/crud/organization/',
  mapping: '/main/crud/mapping/',
  comments: '/main/crud/mapping_comment/',
  geocoverage: '/main/crud/geo_coverage/',
  news: 'news/crud/news/',
  program_areas: '/main/crud/program_area/',
  projects: '/project/organization/',
  ngo_routes: {
    create: '/organization/ngo/create',
    update: '/organization/ngo/update/',
  },
  auth: {
    reset_password: '/account/forgot_password',
    verify_token: '/account/reset_password',
  },
};

export default routes;
