<template>
  <!-- <b-container> -->
  <div>
    <b-row class="p-0" no-gutters align-h="between">
      <b-col cols="12" sm="2" class="sidebar d-none d-sm-block">
        <SideBar />
      </b-col>
      <b-col cols="12" sm="10">
        <b-row class="mb-4 ml-0 px-5 " align-h="between">
          <b-col cols="12" sm="4" class="mt-4 ml-n3">
            <h4 class="text-left text-primary">Datasets</h4>
          </b-col>
          <b-col
            cols="12"
            sm="5"
            class="text-right mt-n1 mr-n2 ml-n3 ml-sm-auto pl-2 justify-content-end d-flex"
          >
            <!-- <base-input
              prepend="fa fa-search"
              prependclass="mt-1"
              :filled="true"
              placeholder="Search"
              width="70%"
            /> -->
          </b-col>
        </b-row>
        <b-row class="p-0" no-gutters align-h="">
          <b-col cols="12" sm="8" class="pl-5">
            <div v-if="loading" class="text-center loading-icon">
              <b-spinner v-if="loading" x-large></b-spinner>
            </div>
            <div v-else>
              <ProjectCard
                v-for="(project, index) in projects.results"
                :key="index"
                :to="`/dataset/view/${project.dataset.id}`"
                :seemorelink="true"
                :date="project.dataset.created_at"
              >
                <template slot="body">
                  <b-card-title class="text-left font-weight-bold text-black-50 h5">
                    {{ project.dataset.name }}
                  </b-card-title>
                  <b-card-text class="text-left text-black-50">
                    No description available
                  </b-card-text>

                  <div class="text-left mt-4">
                    <span class="font-weight-bolder text-black-50">Period of Implementation:</span>
                    <span class="ml-1 text-black-50"
                      >{{ $dataset.formatDate(project.dataset.start_date, 'YYYY') }} -
                      {{ $dataset.formatDate(project.dataset.end_date, 'YYYY') }}</span
                    >
                  </div>

                  <div class="text-left mt-4">
                    <span class="font-weight-bolder text-black-50">Programs Area:</span>
                    <span
                      v-for="(program, index) in project.dataset.program_area"
                      :key="index"
                      class="ml-1 text-primary text-decoration-none"
                      ><span v-if="index !== project.dataset.program_area.length - 1"
                        >{{ program.name }},</span
                      >
                      <span v-else>{{ program.name }}</span></span
                    >
                  </div>
                </template>
              </ProjectCard>
              <div class="mx-auto text-center">
                <Pagination
                  :totalPages="projects.count"
                  :itemCount="projects.results.length"
                  @change-page="changePage($event)"
                  :currentPage="currentPage"
                />
              </div>
            </div>
          </b-col>
          <!-- <b-col cols="4" class="pl-5 d-none d-sm-block">
            <FilterSideBar statesSearchWidth="70%" filterButtonAlign="0rem" />
          </b-col> -->
        </b-row>
      </b-col>
    </b-row>
  </div>
  <!-- </b-container> -->
</template>

<script>
import { mapGetters } from 'vuex';
import SideBar from '../../components/Layout/Sidebar.vue';
import ProjectCard from '../../components/Shared/ProjectCard.vue';
import Pagination from '../../components/Shared/Pagination.vue';
// import FilterSideBar from '../../components/Shared/FilterSideBar.vue';

export default {
  components: {
    ProjectCard,
    Pagination,
    // FilterSideBar,
    SideBar,
  },
  data() {
    return {
      currentPage: 1,
      loading: false,
      projects: {},
    };
  },
  async mounted() {
    this.loading = true;
    try {
      const projects = await this.$dataset.getMyDatasets(this.user.id);
      this.projects = projects.data;
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
  methods: {
    async changePage(e) {
      this.loading = true;
      try {
        const projects = await this.$dataset.getMyDatasets(this.user.id, e);
        this.projects = projects.data;
        this.currentPage = e;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
};
</script>

<style scoped>
.sidebar {
  min-height: 100vh;
  max-height: 100vh;
  margin-top: -2rem;
}
</style>
