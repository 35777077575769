<template>
  <b-container>
    <b-col cols="12" md="9" lg="6" class="mx-auto">
      <b-card
        footer-bg-variant="white"
        style="max-width: auto"
        class="loginshadow mb-3 bg-white text-decoration-none"
      >
        <div class="px-0 px-md-4">
          <b-card-title class="text-center font-weight-bold h5 pt-4">
            Forgot your password
          </b-card-title>
          <b-card-title class="text-center h6">
            Please reset your password to proceed with login
          </b-card-title>
          <b-card-title>
            <b-alert :show="success" variant="primary">
              <span>Redirecting......Please wait</span>
            </b-alert>
          </b-card-title>
          <ValidationObserver v-slot="{ passes }">
            <form @submit.prevent="passes(sendPassword(form))">
              <base-input
                height="2.5rem"
                placeholder="Enter a new password"
                width="100%"
                type="password"
                rules="required"
                name="password"
                ref="password"
                v-model="form.new_password"
              />
              <base-input
                height="2.5rem"
                placeholder="Confirm your new password"
                width="100%"
                rules="required"
                name="Confirm password"
                type="password"
                v-model="form.confirm_password"
              />
              <div></div>
              <button type="submit" class="sharp mt-2" :disabled="loading">
                <b-spinner v-show="loading" small></b-spinner>
                Reset my password
              </button>
            </form>
          </ValidationObserver>
        </div>
      </b-card>
    </b-col>
  </b-container>
</template>

<script>
import { VERIFY_RESET_PASSWORD_TOKEN } from '../../ngo/config/api-modules/api.auth';

export default {
  data() {
    return {
      token: this.$route.params.token,
      form: {},
      loading: false,
      success: false,
    };
  },

  methods: {
    sendPassword(payload) {
      this.loading = true;
      const data = payload;
      data.encoded_token = this.token;
      VERIFY_RESET_PASSWORD_TOKEN(data)
        .then(() => {
          this.success = true;
          this.$toast.show({
            type: 'SUCCESS',
            message: 'Password Updated',
          });
          setTimeout(() => {
            this.$router.push({ path: '/auth/login' });
          }, 3000);
        })
        .catch((error) => {
          this.$toast.show({
            type: 'ERROR',
            message: error.response.data.error,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
