<template>
  <div>
    <div id="ngo-quick-nav" v-if="watchRoute.path.includes('ngo')">
      <div class="d-flex justify-content-between align-items-end container p-2">
        <b-breadcrumb>
          <b-breadcrumb-item to="/ngo/landing">
            <span> NON-GOVERNMENTAL ORGANISATIONS </span></b-breadcrumb-item
          >
          <b-breadcrumb-item href="#bar" class="text-uppercase text-dark">
            {{ watchRoute.name }}</b-breadcrumb-item
          >
        </b-breadcrumb>
        <router-link to="admin"
          ><p class="h6">
            <i class="fa fa-user text-primary mx-2"> </i> NGO Branch Admin
          </p></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ngo-quick-nav',
  data() {
    return {};
  },
  computed: {
    watchRoute() {
      return this.$route;
    },
  },
};
</script>
<style lang="scss" scoped>
$white: #ffff;
* {
  font-family: 'Roboto';
  font-size: 0.725rem;
}
#ngo-quick-nav {
  background-color: $white;
}
.breadcrumb {
  background-color: $white !important;
  padding: 0px !important;
}
.fa {
  font-size: 20px;
}
</style>
