// import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    programsArea: [],
    healthSystemArea: [],
    stakeholderBranches: [],
    stakeholderCategories: [],
    geoCoverage: [],
  },
  mutations: {
    setProgramsArea(state, payload) {
      state.programsArea = payload;
    },
    setHealthSystemArea(state, payload) {
      state.healthSystemArea = payload;
    },
    setStakeholderBranches(state, payload) {
      state.stakeholderBranches = payload;
    },
    setStakeholderCategories(state, payload) {
      state.stakeholderCategories = payload;
    },
    setGeoCoverage(state, payload) {
      state.geoCoverage = payload;
    },
  },
  actions: {
    async SETSYSTEMAREA({ commit }, payload) {
      commit('setHealthSystemArea', payload);
    },
    async SETPROGRAMAREAS({ commit }, payload) {
      commit('setProgramsArea', payload);
    },
    async SETSTAKEHOLDERSBRANCHES({ commit }, payload) {
      commit('setStakeholderBranches', payload);
    },
    async SETSTAKEHOLDERCATEGORIES({ commit }, payload) {
      commit('setStakeholderCategories', payload);
    },
    async SETGEOCOVERAGE({ commit }, payload) {
      commit('setGeoCoverage', payload);
    },
  },

  getters: {
    getStakeholderBranches: (state) => state.stakeholderBranches,
    getStakeholderCategories: (state) => state.stakeholderCategories,
    getProgramsArea: (state) => state.programsArea,
    getHealthSystemArea: (state) => state.healthSystemArea,
    getGeoCoverage: (state) => state.geoCoverage,
    getGeoCoverageFederalLevel: (state) =>
      state.geoCoverage.filter((item) => {
        return item.parent === null;
      }),
    getGeoCoverageStateLevel: (state) =>
      state.geoCoverage.filter((item) => {
        return item.level === 'State';
      }),
    getGeoCoverageLgaLevel: (state) => (parentId) =>
      state.geoCoverage.filter((item) => {
        return Number(item.parent) === Number(parentId) && item.level === 'Lga';
      }),
  },
};
