<template>
  <b-container>
    <div v-if="loadingData" class="text-center loading-icon">
      <b-spinner v-if="loadingData" x-large></b-spinner>
    </div>
    <div v-else>
      <b-row class="mb-4 ml-1">
        <h4 class="text-left text-primary ml-n1">Submit Datasets</h4>
      </b-row>
      <b-row class="mb-4 ml-1">
        <p class="text-left text-black ml-n1">
          The Federal Ministry of Health is compiling all data related projects ad activities
          (surveys, mapping projects, data sources) across all health domains in the country into a
          central inventory developed at the federal level and made accessible through this
          platform.
          <br />
          <br />
          The Federal Ministry of Health hereby reaches out to all partners and stakholders for
          accurate information on all previous (past 10 years) and ongoing health data related
          projects in its bid to develop this web based central repository, and to create, implement
          and strengthen a single, unified and coherent health information system at the national
          level.
          <br />
          We anticipate your coorperation and timely response. Thank you.
        </p>
      </b-row>
      <b-row class="mb-4 ml-1">
        <p class="text-left text-grey pt-4 pb-3 border-top w-100">
          Please complete the following questions by selecting the answer(s) of your choice or
          writing in the space provided
        </p>
      </b-row>
      <ValidationObserver v-slot="{ passes }">
        <form @submit.prevent="passes(submitDataset)">
          <base-input
            v-model="name"
            name="Name of data collection activity/survey"
            rules="required"
            label="Name of data collection activity/survey"
          />
          <base-input
            name="Type of data collection activity/survey"
            v-model="survey_type"
            rules="required"
            description="e.g, Survey Mapping"
            label="Type of data collection activity/survey"
          />
          <!-- <base-input
            name="Name of Implementation Organization(s)"
            rules="required"
            label="Name of Implementation Organization(s)"
          /> -->

          <p class="text-left font-weight-bold text-black-50 mb-1 mt-4">
            Name of Implementation Organization(s)
          </p>
          <div style="width: 98%">
            <multiselect
              v-model="implementingPartner"
              :options="organizations"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Choose Collaborating Partners"
              label="name"
              track-by="id"
              :preselect-first="true"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen"
                  >{{ implementingPartner.length }} Implementing partner selected</span
                ></template
              >
            </multiselect>
          </div>

          <p class="text-left font-weight-bold text-black-50 mb-1 mt-4">
            Name of Collaborating Organization(s)
          </p>
          <div style="width: 98%">
            <multiselect
              v-model="collaboratingPartner"
              :options="organizations"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Choose Collaborating Partners"
              label="name"
              track-by="id"
              :preselect-first="true"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen"
                  >{{ collaboratingPartner.length }} collaborating partner selected</span
                ></template
              >
            </multiselect>
          </div>

          <p class="text-left font-weight-bold text-black-50 mb-1 mt-4">
            Funder(s)
          </p>
          <p class="text-left text-black-50 font-weight-medium mb-1 mt-n0">
            Please provide information on any organizations or agencies that funded this
            intervention
          </p>
          <div style="width: 98%">
            <multiselect
              v-model="funders"
              :options="organizations"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Choose Funders for this Project"
              label="name"
              track-by="id"
              :preselect-first="true"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen"
                  >{{ funders.length }} funder selected</span
                ></template
              >
            </multiselect>
          </div>
          <!-- <div class="d-flex">
            <div style="width: 100%">
              <base-input
                v-for="(input, n) in collaboratingPartner"
                :key="n"
                name="Collaborating Organization(s)"
                rules="required"
                label="Name of Collaborating Organization(s)"
                width="100%"
              />
            </div>
            <div style="width: 2%" class="mt-5">
              <b-icon
                icon="plus-circle"
                class="mt-3 ml-1"
                @click="addNewCollaboratingPartner"
              ></b-icon>
            </div>
          </div> -->
          <!-- <div class="d-flex">
            <div style="width: 100%">
              <base-input
                v-for="(input, n) in funders"
                :key="n"
                name="Funder"
                rules="required"
                label="Funder(s)"
                width="100%"
                description="Please provide information on any organizations
            or agencies that funded this intervention"
              />
            </div>
            <div style="width: 2%" class="mt-5">
              <b-icon icon="plus-circle" class="mt-4 mb-n5 ml-1" @click="addNewFunder"></b-icon>
            </div>
          </div> -->
          <base-textarea
            label="Abstract or summary of data collection activity/survey"
            name="Abstract or summary of data"
            description="Please provide information on target population of intervention"
            rules="required"
          />
          <p class="text-left font-weight-bold text-black mb-0 mt-4">
            Period of implementation(please provide start and end dates of project)
          </p>
          <b-row class="mb-n2">
            <b-col cols="12" md="3">
              <base-select
                :items="months"
                v-model="startMonth"
                track="name"
                label="Start Month"
                width="92%"
                name="Start Month"
                rules="required"
              />
            </b-col>
            <b-col cols="12" md="3">
              <base-select
                :items="generateArrayOfYears()"
                label="Start Year"
                v-model="startYear"
                width="92%"
                name="Start Year"
                rules="required"
              />
            </b-col>
            <b-col cols="12" md="3">
              <base-select
                :items="months"
                v-model="endMonth"
                track="name"
                label="End month"
                width="92%"
                name="End month"
                rules="required"
              />
            </b-col>
            <b-col cols="12" md="3">
              <base-select
                :items="generateArrayOfYears()"
                label="End Year"
                v-model="endYear"
                width="92%"
                name="End Year"
                rules="required"
              />
            </b-col>
          </b-row>
          <b-row class="mb-n2 mt-2">
            <b-col cols="12" md="5">
              <base-select
                label="Status of implementation"
                v-model="status"
                :items="['Completed', 'Ongoing', 'Abandonded']"
                name="Status of implementation"
                description="What is the current status of the implementation?"
                rules="required"
              />
            </b-col>
          </b-row>
          <p class="text-left font-weight-bold text-black-50 mb-4 mt-4 pt-2">
            Frequency of implementation
          </p>
          <p class="text-black-600 ml-0 font-weight-light mb-1 mt-n4">
            How often is this data collection activity implemented?
          </p>

          <b-row class="mt-2">
            <b-col cols="6" v-for="(implentation, index) in implementationFrequency" :key="index">
              <input
                type="radio"
                :id="implentation"
                name="implementationFrequency"
                :value="implentation"
              />
              <label :for="implentation" class="ml-2">{{ implentation }}</label
              ><br />
            </b-col>
          </b-row>

          <p class="text-left font-weight-bold text-black-50 pb-3 mt-4">Geographical coverage</p>
          <base-checkbox
            v-for="(level, index) in geoCoverageBaseLevels"
            v-model="geoCoverageBaseLevelSelected"
            :key="index"
            :item="level"
            class="pl-2 mt-n5"
            :checkboxItem="level.name"
          />
          <div v-if="showGeoCoverageForFederal">
            <p class="text-left font-weight-bold text-black-50 pb-3 mt-4">Federal</p>
            <base-checkbox
              v-for="(federalLevel, index) in getGeoCoverageFederalLevel"
              v-model="geoCoverageSelected"
              :key="index"
              :item="federalLevel"
              class="pl-2 mt-n5"
              :checkboxItem="federalLevel.name"
            />
          </div>

          <div v-if="showGeoCoverageForState">
            <p class="text-left font-weight-bold text-black-50 pb-4 mt-4">State Level</p>
            <b-row class="pl-2">
              <b-col
                v-for="(statelLevel, index) in getGeoCoverageStateLevel"
                :key="index"
                cols="12"
                lg="3"
                class="mt-n4"
              >
                <base-checkbox
                  v-model="geoCoverageSelected"
                  :key="index"
                  :item="statelLevel"
                  class="pl-0 mt-n5"
                  :checkboxItem="statelLevel.name"
                />
              </b-col>
            </b-row>
          </div>

          <div v-if="showGeoCoverageForLga">
            <p class="text-left font-weight-bold text-black-50 pb-0 mt-4">LGA's Level</p>
            <p class="text-left text-black-50 font-weight-medium mb-4 pb-1 mt-n3">
              Select a State to get It's LGA's
            </p>
            <div class="states">
              <base-checkbox
                v-for="(statelLevel, index) in getGeoCoverageStateLevel"
                :key="index"
                v-model="geoCoverageLgaSelected"
                :item="statelLevel"
                class="pl-2 mt-n5"
                :checkboxItem="statelLevel.name"
              />
            </div>
          </div>

          <div v-if="lgasToShow.length > 0">
            <div v-for="(lgaData, index) in lgasToShow" :key="index">
              <p class="text-left font-weight-bold text-black-50 pb-3 mt-4">
                {{ lgaData.state.name }} State LGA's
              </p>
              <b-row>
                <b-col
                  v-for="(lgaData, index) in lgaData.lga"
                  :key="index"
                  cols="12"
                  lg="3"
                  class="mt-n4"
                >
                  <base-checkbox
                    v-model="geoCoverageSelected"
                    :item="lgaData"
                    class="pl-2 mt-n5"
                    :checkboxItem="lgaData.name"
                  />
                </b-col>
              </b-row>
            </div>
          </div>

          <p class="text-left font-weight-bold text-black-50 mb-1 mt-4">
            Area of data collection activity/survey
          </p>
          <p class="text-left text-black-50 font-weight-medium mb-5 mt-n0">
            Tick all that apply
          </p>
          <b-row class="pl-2">
            <b-col
              v-for="(program, index) in getProgramsArea.results"
              :key="index"
              cols="12"
              lg="3"
              class="mt-n4"
            >
              <base-checkbox
                :checkboxItem="program.name"
                v-model="programSelected"
                class="mt-n5"
                :item="program"
              />
              <!-- @change="addEditProgramsArea(program)" -->
            </b-col>
          </b-row>

          <!-- <div class="d-flex">
            <div style="width: 100%">
              <base-input
                v-for="(input, n) in areaOfDataCollection"
                :key="n"
                name="Other areas of data collection activity/survey - please specify"
                rules="required"
                label="Other data collection areas"
                width="100%"
              />
            </div>
            <div style="width: 2%" class="mt-5">
              <b-icon
                icon="plus-circle"
                class="mt-3 mb-n0 ml-1"
                @click="addNewAreaOfDataCollection"
              ></b-icon>
            </div>
          </div> -->
          <base-input
            name="Population Target"
            v-model="population_target"
            rules="required"
            label="Target group/ population of interest"
            description="e.g Infants, Children under five, Adolescents, Mothers, Women of reproductive age,
            Adults, Men, Elderly, Youth etc"
            width="100%"
          />

          <p class="text-left font-weight-bold text-black-50 mb-1 mt-4">
            Population unit
          </p>
          <p class="text-left text-black-50 font-weight-medium mb-5 mt-n0">
            Select the unit of population that data was/is collected from Tick all that apply
          </p>
          <!-- <b-row class="pl-2"> -->
          <!-- <b-col
              v-for="(program, index) in getProgramsArea.results < 1" {
              :key="index"
              cols="12"
              lg="3"
              class="mt-n4"
            > -->
          <base-checkbox
            v-for="(pu, index) in populationUnit"
            :key="index"
            :checkboxItem="pu.name"
            v-model="populationUnitSelected"
            class="mt-n5 ml-2"
            :item="pu"
          />
          <!-- @change="addEditProgramsArea(program)" -->
          <!-- </b-col> -->
          <!-- </b-row> -->

          <p class="text-left font-weight-bold text-black-50 mb-4 mt-4 pt-2 border-bottom">
            Data Collection Methodology
          </p>

          <base-input
            name="Sample Size"
            v-model="sample_size"
            rules=""
            type="number"
            label="Sample size (if Applicable)"
            description="Provide the number of respondents that data was collected from."
            width="100%"
          />

          <base-textarea
            v-model="sample_procedure"
            label="Sampling procedure (if applicable)"
            name="Sampling procedure"
            description="Please provide information on how the target group was selected for data collection. E.g Simple random sampling, cluster sampling, systematic sampling, or multistage sampling."
            rules=""
          />

          <base-select
            label="Was there an error rectifying system"
            v-model="errorRectify"
            :items="['No', 'Yes']"
            name="Status of implementation"
            description="Eg. NHREC, IRB, etc."
            rules="required"
          />

          <p class="text-black-600 ml-0 font-weight-light mb-n3 mt-5">
            Data Collection Period (if applicable)
          </p>
          <b-row class="mb-n2">
            <b-col cols="12" md="3">
              <base-select
                :items="months"
                v-model="commencementStartMonth"
                track="name"
                label="Commencement Month"
                width="92%"
                name="Commencement Month"
                rules="required"
              />
            </b-col>
            <b-col cols="12" md="3">
              <base-select
                :items="generateArrayOfYears()"
                v-model="commencementStartYear"
                label="Commencement Year"
                width="92%"
                name="Commencement Year"
                rules="required"
              />
            </b-col>
            <b-col cols="12" md="3">
              <base-select
                :items="months"
                v-model="commencementEndMonth"
                track="name"
                label="Completion Month"
                width="92%"
                name="Completion Month"
                rules="required"
              />
            </b-col>
            <b-col cols="12" md="3">
              <base-select
                :items="generateArrayOfYears()"
                v-model="commencementEndYear"
                label="Completion Year"
                width="92%"
                name="Completion Year"
                rules="required"
              />
            </b-col>
          </b-row>

          <base-input
            name="Data collection tool"
            v-model="collection_app_name"
            rules=""
            label="Data collection tool"
            description="Please provide information on the type of tool used to collect data e.g Paper questionnaire, Mobile device, DHIS2, ODK, Survey CTO etc."
            width="100%"
          />

          <base-textarea
            v-model="micro_data_access_authority"
            label="Micro data access authority"
            name="Micro data access authority"
            description="Provide information on who should have access to the micro data."
            rules=""
          />

          <p class="text-left font-weight-bold text-black-50 mb-1 mt-4">
            Micro data access conditions
          </p>
          <p class="text-left text-black-50 font-weight-medium mb-4 mt-n0">
            What conditions should be fulfilled before access to micro data is provided to a user?
          </p>

          <div class="d-flex mt-n5 pt-1">
            <div style="width: 100%">
              <base-input
                v-for="(input, n) in microDataAccessConditions"
                :key="n"
                v-model="input.condition"
                rules=""
                width="100%"
              />
            </div>
            <div style="width: 2%" class="mt-4 pt-2">
              <b-icon
                icon="plus-circle"
                class="mt-n5 ml-1"
                @click="addNewMicroDataAccessConditions"
              ></b-icon>
            </div>
          </div>

          <p class="text-left font-weight-bold text-black-50 mb-4 mt-4 pt-2 border-bottom">
            Contact information
          </p>
          <p class="text-left font-weight-bold text-black-50 mb-0 mt-4 pt-3">
            Name of contact person
          </p>
          <b-row class="mb-n2">
            <b-col cols="6">
              <base-input
                width="98%"
                v-model="contact_person_first_name"
                name="First Name"
                rules="required"
                label="First Name"
              />
            </b-col>
            <b-col cols="6">
              <base-input
                name="Last Name"
                v-model="contact_person_last_name"
                width="96%"
                rules="required"
                label="Last Name"
              />
            </b-col>
          </b-row>
          <base-input
            name="Contact Person Phone number"
            v-model="contact_person_phone"
            rules="required"
            label="Phone number of contact person"
          />
          <base-input
            name="Contact Person email"
            v-model="contact_person_email"
            rules="required"
            label="Email of contact person"
          />
          <base-input
            v-model="wepageOfDataCollection"
            name="Webpage of data collection activity/survey - please specify"
            rules="required"
            label="Webpage of data collection activity/survey - please specify"
            description="please provide url(if available) of data project"
            width="100%"
          />
          <p class="text-left font-weight-bold text-black-50 mb-4 mt-4 pt-2 border-bottom">
            File Upload
          </p>
          <!-- <b-row class="ml-1 mt-5">
            <base-file-input class="ml-n1" label="Upload project [select file pdf. docx.]" />
          </b-row> -->
          <p class="text-left font-weight-bold text-black-50 mb-0 mt-4 pb-1 ml-0 mt-5">
            Upload data collection tool
          </p>
          <p class="text-black-600 ml-0 font-weight-light mb-1 mt-n1">
            If applicable
          </p>
          <!-- <br /> -->
          <input
            type="file"
            class="ml-0 mt-0"
            accept="image/*"
            @change="collectionToolSelected($event.target.files)"
          />
          <p class="text-left font-weight-bold text-black-50 mb-0 mt-4 pb-1 ml-0 mt-5">
            Upload Codebook or Data dictionary containing all variables collected (if available)
          </p>
          <p class="text-black-600 ml-0 font-weight-light mb-1 mt-n1">
            Please provide document that contains a full list describing all variables in the data
            sets and full details about the coding and classifications used for the variables
          </p>
          <!-- <br /> -->
          <input
            type="file"
            class="ml-0 mt-0"
            accept="image/*"
            @change="codeBookSelected($event.target.files)"
          />
          <p class="text-left font-weight-bold text-black-50 mb-0 mt-4 pb-1 ml-0 mt-5">
            Upload Report or project summary
          </p>
          <!-- <br /> -->
          <input
            type="file"
            class="ml-0 mt-0"
            accept="image/*"
            @change="reportSelected($event.target.files)"
          />
          <p class="text-left font-weight-bold text-black-50 mb-0 mt-4 pb-1 ml-0 mt-5">
            Upload micro data or raw data sets resulting from the data collection activity/survey
          </p>
          <!-- <br /> -->
          <input
            type="file"
            class="ml-0 mt-0"
            accept="image/*"
            @change="microDataSelected($event.target.files)"
          />
          <b-row class="ml-0 mt-5">
            <button type="submit" class="sharp ml-n1">
              <b-spinner v-if="loading" small></b-spinner> <span v-else>Submit</span>
            </button>
            <button type="button" class="save ml-0 ml-sm-n1">Save and Continue Later</button>
          </b-row>
        </form>
      </ValidationObserver>
    </div>
  </b-container>
</template>

<script>
import { toast } from '@/plugin/customToast';
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';

export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      implementationFrequency: [
        'Daily',
        'Monthly',
        'Biannually',
        'Once',
        'Weekly',
        'Quarterly',
        'Annually',
        // 'Others',
      ],
      loadingData: false,
      survey_type: '',
      errorRectify: '',
      showGeoCoverageForState: false,
      showGeoCoverageForFederal: false,
      showGeoCoverageForLga: false,
      loading: false,
      name: '',
      status: '',
      contact_person_first_name: '',
      contact_person_last_name: '',
      contact_person_phone: '',
      contact_person_email: '',
      population_target: '',
      micro_data_access_authority: '',
      collection_app_name: '',
      sample_size: '',

      start_date: '',
      end_date: '',
      startYear: '',
      startMonth: '',
      endYear: '',
      endMonth: '',

      commencement_date: '',
      completion_date: '',
      commencementStartYear: '',
      commencementStartMonth: '',
      commencementEndYear: '',
      commencementEndMonth: '',

      collection_tool: null,
      code_book: null,
      report: null,
      micro_data: null,

      sample_procedure: '',
      organizations: [],
      wepageOfDataCollection: 'http://',
      collaboratingPartner: [],
      implementingPartner: [],
      microDataAccessConditions: [{ condition: '' }],
      funders: [],
      areaOfDataCollection: [{ areaofdata: '' }],
      pdObjectives: [{ objective: '' }],
      outcomes: [{ outcome: '' }],
      achievements: [{ achievement: '' }],
      challenges: [{ challenge: '' }],
      geoCoverageBaseLevelSelected: {},
      geoCoverageFederalSelected: {},
      geoCoverageStateSelected: {},
      geoCoverageLgaSelected: {},
      geoCoverageStateLgsSelected: {},
      geoCoverageSelected: {},
      lgasToShow: [],
      programSelected: [],
      populationUnitSelected: [],
      geoCoverageBaseLevels: [
        {
          name: 'Federal Level',
          parent: null,
          level: '',
        },
        {
          name: 'State Level',
          parent: '',
          level: 'State',
        },
        {
          name: "LGA's Level",
          parent: '',
          level: 'Lga',
        },
      ],
      geoCoverage: [],
      programsAreaSelectedToSend: [],
      populationUnitSelectedToSend: [],
      completedProject: false,
      error_rectify: false,
      months: [
        {
          no: 1,
          name: 'January',
        },
        {
          no: 2,
          name: 'February',
        },
        {
          no: 3,
          name: 'March',
        },
        {
          no: 4,
          name: 'April',
        },
        {
          no: 5,
          name: 'May',
        },
        {
          no: 6,
          name: 'June',
        },
        {
          no: 7,
          name: 'July',
        },
        {
          no: 8,
          name: 'August',
        },
        {
          no: 9,
          name: 'September',
        },
        {
          no: 10,
          name: 'October',
        },
        {
          no: 11,
          name: 'January',
        },
        {
          no: 12,
          name: 'December',
        },
      ],
      populationUnit: [],
    };
  },
  async mounted() {
    try {
      this.loadingData = true;
      // check for main items

      if (
        this.getProgramsArea.results < 1 ||
        this.getHealthSystemArea.results < 1 ||
        this.getGeoCoverage < 1 ||
        this.getGeoCoverageFederalLevel < 1 ||
        this.getGeoCoverageStateLevel < 1 ||
        this.getGeoCoverageLgaLevel < 1
      ) {
        const programsAreaData = await this.$dataset.getAllProgramAreas();
        const healthAreas = await this.$dataset.getAllHealthAreas();
        const categories = await this.$dataset.getAllStakeholdersCategory();
        const branches = await this.$dataset.getAllStakeholdersBranch();
        const geoCoverage = await this.$dataset.getAllGeoCoverage();
        console.log('programsAreaData.data', programsAreaData.data);
        await this.SETPROGRAMAREAS(programsAreaData.data);
        await this.SETSYSTEMAREA(healthAreas.data);
        await this.SETSTAKEHOLDERSBRANCHES(branches.data);
        await this.SETSTAKEHOLDERCATEGORIES(categories.data);
        await this.SETGEOCOVERAGE(geoCoverage.data.results);
      }

      const organizationData = await this.$dataset.getAllOrganization();
      const { data } = await this.$dataset.getPopulationUnit();
      this.populationUnit = data.results;
      this.organizations = organizationData.data.results;
      console.log(data);
      this.loadingData = false;
    } catch (error) {
      this.loadingData = false;
    }
  },
  methods: {
    ...mapActions('DATASET_STORE', [
      'SETPROGRAMAREAS',
      'SETSYSTEMAREA',
      'SETSTAKEHOLDERSBRANCHES',
      'SETSTAKEHOLDERCATEGORIES',
      'SETGEOCOVERAGE',
    ]),
    collectionToolSelected(file) {
      // eslint-disable-next-line prefer-destructuring
      this.collection_tool = file[0];
    },
    codeBookSelected(file) {
      // eslint-disable-next-line prefer-destructuring
      this.code_book = file[0];
    },
    reportSelected(file) {
      // eslint-disable-next-line prefer-destructuring
      this.report = file[0];
    },
    microDataSelected(file) {
      // eslint-disable-next-line prefer-destructuring
      this.micro_data = file[0];
    },
    generateArrayOfYears() {
      const max = new Date().getFullYear();
      const min = max - 80;
      const years = [];
      // eslint-disable-next-line no-plusplus
      for (let i = max; i >= min; i--) {
        years.push(i);
      }
      return years;
    },
    async submitDataset() {
      const periodSelected = document.querySelector('input[name="implementationFrequency"]:checked')
        .value;
      this.loading = true;
      try {
        const microDataAccessCondition = this.microDataAccessConditions.map((item) => {
          return item.condition;
        });
        const dataToSubmit = {
          name: this.name,
          completed: this.completedProject,
          survey_type: this.survey_type,
          abstract: '',
          start_date: this.start_date,
          end_date: this.end_date,
          period: periodSelected,
          population_target: this.population_target,
          sample_procedure: this.sample_procedure,
          error_rectify: this.error_rectify,
          commencement_date: this.commencement_date,
          completion_date: this.completion_date,
          collection_app_name: this.collection_app_name,
          micro_data_access_authority: this.micro_data_access_authority,
          micro_data_access_condition: JSON.stringify(microDataAccessCondition),
          contact_person_first_name: this.contact_person_first_name,
          contact_person_last_name: this.contact_person_last_name,
          contact_person_phone: this.contact_person_phone,
          contact_person_email: this.contact_person_email,
          webpage_url: this.wepageOfDataCollection,
          organization: this.user.id,
          program_area: this.programsAreaSelectedToSend,
          population_unit: this.populationUnitSelectedToSend,
          geo_coverage: this.geoCoverage,
        };

        const { data } = await this.$dataset.createDataset(dataToSubmit);
        console.log('data', data);

        const associateData = {
          dataset: data.id,
          organizations_roles: [],
        };
        this.funders.forEach((item) => {
          const funderData = { organization: item.id, role: 1 };
          associateData.organizations_roles.push(funderData);
        });
        this.collaboratingPartner.forEach((item) => {
          const collaborData = { organization: item.id, role: 2 };
          associateData.organizations_roles.push(collaborData);
        });
        this.implementingPartner.forEach((item) => {
          const implentingData = { organization: item.id, role: 3 };
          associateData.organizations_roles.push(implentingData);
        });

        await this.$dataset.associateDatasetOrganizations(associateData);

        const projectDocument = new FormData();
        if (this.collection_tool) {
          projectDocument.append('collection_tool', this.collection_tool);
        }
        if (this.code_book) {
          projectDocument.append('code_book', this.code_book);
        }
        if (this.report) {
          projectDocument.append('report', this.report);
        }
        if (this.micro_data) {
          projectDocument.append('micro_data', this.micro_data);
        }
        if (this.collection_tool || this.micro_data || this.code_book || this.report) {
          await this.$dataset.editDataset(data.id, projectDocument);
        }
        toast.show({
          type: 'SUCCESS',
          message: 'Dataset Submitted Sucessfully!',
        });

        // console.log('associateData', associateData);
        this.loading = false;
        this.$router.push('/dataset/project-inventory');
      } catch (error) {
        this.loading = false;
      }
    },
    // addNewCollaboratingPartner() {
    //   this.collaboratingPartner.push({ cPartner: '' });
    // },
    addNewMicroDataAccessConditions() {
      this.microDataAccessConditions.push({ condition: '' });
    },
    addNewAreaOfDataCollection() {
      this.areaOfDataCollection.push({ areaofdata: '' });
    },
    // addNewFunder() {
    //   this.funders.push({ funder: '' });
    // },
    addpdObjective() {
      this.pdObjectives.push({ objective: '' });
    },
    addExpectedOutcomes() {
      this.outcomes.push({ outcome: '' });
    },
    addAchievement() {
      this.achievements.push({ achievement: '' });
    },
    addChallenges() {
      this.challenges.push({ challenge: '' });
    },
  },
  computed: {
    ...mapGetters('DATASET_STORE', [
      'getProgramsArea',
      'getHealthSystemArea',
      'getGeoCoverage',
      'getGeoCoverageFederalLevel',
      'getGeoCoverageStateLevel',
      'getGeoCoverageLgaLevel',
    ]),
    ...mapGetters('auth', ['user']),
  },
  watch: {
    commencementStartMonth(val) {
      this.commencement_date = `${this.commencementStartYear}-${val.no}-01`;
      console.log(this.commencement_date);
    },
    commencementStartYear(val) {
      this.commencement_date = `${val}-${this.commencementStartMonth.no}-01`;
      console.log(this.commencement_date);
    },
    commencementEndMonth(val) {
      this.completion_date = `${this.commencementEndYear}-${val.no}-01`;
      console.log(this.completion_date);
    },
    commencementEndYear(val) {
      this.completion_date = `${val}-${this.commencementEndMonth.no}-01`;
      console.log(this.completion_date);
    },
    startMonth(val) {
      this.start_date = `${this.startYear}-${val.no}-01`;
      console.log(this.start_date);
    },
    startYear(val) {
      this.start_date = `${val}-${this.startMonth.no}-01`;
      console.log(this.start_date);
    },
    endMonth(val) {
      this.end_date = `${this.endYear}-${val.no}-01`;
      console.log(this.end_date);
    },
    endYear(val) {
      this.end_date = `${val}-${this.endMonth.no}-01`;
      console.log(this.end_date);
    },
    populationUnitSelected(val) {
      const indexOfItem = this.populationUnitSelectedToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.populationUnitSelectedToSend.splice(indexOfItem, 1);
        // console.log('populationUnitSelectedToSend', this.populationUnitSelectedToSend);
        return;
      }
      this.populationUnitSelectedToSend.push(Number(val.item.id));
      // console.log('programsAreaSelectedToSend', this.programsAreaSelectedToSend);
    },
    programSelected(val) {
      const indexOfItem = this.programsAreaSelectedToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.programsAreaSelectedToSend.splice(indexOfItem, 1);
        // console.log('programsAreaSelectedToSend', this.programsAreaSelectedToSend);
        return;
      }
      this.programsAreaSelectedToSend.push(Number(val.item.id));
      // console.log('programsAreaSelectedToSend', this.programsAreaSelectedToSend);
    },
    status(val) {
      // if (val === 'Ongoing') {
      //   this.abandoned = false;
      //   this.ongoing = true;
      //   return;
      // }
      // if (val === 'Abandonded') {
      //   this.ongoing = false;
      //   this.abandoned = true;
      //   return;
      // }
      if (val === 'Completed') {
        this.completedProject = true;
        return;
      }
      this.completedProject = false;
      // this.ongoing = false;
    },
    errorRectify(val) {
      if (val === 'Yes') {
        this.error_rectify = true;
        return;
      }
      this.error_rectify = false;
    },
    geoCoverageSelected(val) {
      const indexOfItem = this.geoCoverage.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.geoCoverage.splice(indexOfItem, 1);
        return;
      }
      this.geoCoverage.push(Number(val.item.id));
    },
    geoCoverageLgaSelected(val) {
      const indexOfItem = this.lgasToShow
        .map((item) => {
          return item.state.id;
        })
        .indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.lgasToShow.splice(indexOfItem, 1);
        return;
      }
      const lgas = this.getGeoCoverageLgaLevel(val.item.id);
      const data = {
        state: val.item,
        lga: lgas,
      };
      this.lgasToShow.push(data);
    },
    geoCoverageBaseLevelSelected(val) {
      if (val.item.name === 'Federal Level' && val.item.parent === null && val.selected) {
        this.showGeoCoverageForFederal = true;
        return;
      }
      if (val.item.name === 'Federal Level' && val.item.parent === null && !val.selected) {
        this.showGeoCoverageForFederal = false;
        return;
      }
      if (val.item.level === 'State' && val.item.parent === '' && val.selected) {
        this.showGeoCoverageForState = true;
        return;
      }
      if (val.item.level === 'State' && val.item.parent === '' && !val.selected) {
        this.showGeoCoverageForState = false;
        return;
      }
      if (val.item.level === 'Lga' && val.item.parent === '' && val.selected) {
        this.showGeoCoverageForLga = true;
        return;
      }
      if (val.item.level === 'Lga' && val.item.parent === '' && !val.selected) {
        this.showGeoCoverageForLga = false;
        return;
      }
      console.log(val);
    },
  },
};
</script>

<style scoped>
.multiselect {
  border: 1px solid #707070 !important;
}

button {
  display: inline-block;
  padding: 10px 29px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 10px 16px;
  outline: none;
}

.sharp {
  width: 12em;
  color: #fff;
  background-color: #30ab4c;
  border-color: #0b7022;
}

.save {
  background-color: #fff;
  color: #30ab4c;
  border-color: #0b7022;
  border-radius: 0;
}

.save:hover,
.save:focus,
.save:active,
.save.active,
.open .dropdown-toggle.save {
  background-color: #ffffff;
  color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open .dropdown-toggle.sharp {
  border-radius: 0;
  color: #fff;
  background-color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

/***********************
  SHARP BUTTONS
************************/
.sharp {
  outline: none;
  border-radius: 0;
}

/***********************
  CUSTON BTN VALUES
************************/

button {
  padding: 14px 24px;
  outline: none;
  border: 0 none;
  /* font-weight: 700; */
  letter-spacing: 1px;
}
button:focus,
button:active:focus,
button.active:focus {
  outline: 0 none;
}

.sharp {
  background: #30ab4c;
  color: #ffffff;
}
.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open > .dropdown-toggle.sharp {
  background: #30ab4c;
}
.sharp:active,
.sharp.active {
  background: #45cc64;
  box-shadow: none;
}
.save:active,
.save.active {
  background: #d8f7e0;
  color: #30ab4c;
  box-shadow: none;
}
</style>
