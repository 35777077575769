import { Roles } from '@/modules/auth/Role';
import index from './views/index.vue';
import ChildRoute from './views/ChildRoute.vue';
// import LoginPage from './views/Authentication/Login.vue';
// import RegisterPage from './views/Authentication/Register.vue';
import ProjectInventoryPage from './views/Project-inventory/ExploreProjects.vue';
import SubmitProjectPage from './views/Project-inventory/SubmitProject.vue';
import ProjectHomePage from './views/Project-inventory/index.vue';
import ExploreDatasetPage from './views/Dataset-Inventory/ExploreDataset.vue';
import GetMicroDataPage from './views/Dataset-Inventory/RequestDataset/GetMicroData.vue';
import CreateProjectPage from './views/Dataset-Inventory/RequestDataset/CreateProject.vue';
import SelectStatePage from './views/Dataset-Inventory/RequestDataset/SelectState.vue';
import SelectedStatePage from './views/Dataset-Inventory/RequestDataset/SelectedState.vue';
import SubmitDatasetPage from './views/Dataset-Inventory/SubmitDataset.vue';
import DatasetHomePage from './views/Dataset-Inventory/index.vue';
import UserDataset from './views/Dataset-Inventory/userDataset.vue';
import DetailsProjectPage from './views/ProjectDetails/Project/_id.vue';
import DetailsDatasetPage from './views/ProjectDetails/Dataset/_id.vue';
import StakeholdersPage from './views/Stakeholders/index.vue';
import StakeholderDetailPage from './views/Stakeholders/details/_id.vue';
import StakeholderProjectPage from './views/Stakeholders/project.vue';
import terms from './views/terms/terms.vue';

export default [
  {
    path: '/dataset',
    component: index,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: '',
        component: DatasetHomePage,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'my-dataset',
        component: UserDataset,
        meta: {
          requiresAuth: true,
          roles: [Roles.User],
        },
      },
      {
        path: 'project',
        component: ProjectHomePage,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'stakeholders',
        component: ChildRoute,
        meta: {
          requiresAuth: false,
        },
      },
      // {
      //   path: 'login',
      //   component: LoginPage,
      // },
      // {
      //   path: 'register',
      //   component: RegisterPage,
      // },
      {
        path: 'getmicrodata/:id',
        component: GetMicroDataPage,
        props: true,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'create-project/:id',
        component: CreateProjectPage,
        props: true,
        meta: {
          requiresAuth: true,
          roles: [Roles.User],
        },
      },
      {
        path: 'select-state',
        component: SelectStatePage,
      },
      {
        path: 'selected-state',
        component: SelectedStatePage,
      },
      {
        path: 'project-inventory',
        component: ProjectInventoryPage,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'submit-project',
        component: SubmitProjectPage,
        meta: {
          requiresAuth: true,
          roles: [Roles.Organization, Roles.ngo],
        },
      },
      {
        path: 'dataset-inventory',
        component: ExploreDatasetPage,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'submit-dataset',
        component: SubmitDatasetPage,
        meta: {
          requiresAuth: true,
          roles: [Roles.Organization, Roles.ngo],
        },
      },
      {
        path: 'project/:id',
        component: DetailsProjectPage,
        props: true,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'view/:id',
        component: DetailsDatasetPage,
        props: true,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'stakeholder/:id',
        component: StakeholdersPage,
        props: true,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'stakeholder/view/:id/:name?',
        component: StakeholderDetailPage,
        props: true,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'stakeholder/project/:id',
        component: StakeholderProjectPage,
        props: true,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'terms',
        component: terms,
        props: true,
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
];
