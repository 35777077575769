<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="text-center text-underline"><i> Terms of Data Use </i></h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="text-black lead">
          The Federal Ministry of Health’s Department of Health Planning Research and Statistics
          (FMoH-DHPRS) is authorized to distribute datasets for legitimate academic research. If you
          agree with the underlisted terms of data use, please endorse your acceptance below and
          forward to the Head of the Monitoring and Evaluation Division via mail at:
          charlesnzelu@yahoo.co.uk.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col text-center">
        <p class="text-center h4 font-weight-light text-black">
          <u>Conditions of Use for The Health Partners Project and Data Mapping (HPPDM) datasets</u>
          <br />
          <span class="h6 font-weight-light">
            (This applies to all datasets downloaded from the <a href="mapping.fmohconnect.gov.ng">HPPDM</a>)</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="lead">
        <ol>
          <li>
            Agree to use the requested data only for the registered research or study. Agree to
            submit a new research project request if I want to use the data for another purpose
            (using the “Create a New Project” link in my HPPDM user account)
          </li>
          <li>
            Agree that the datasets will not be shared with other researchers without the written
            consent of the FMoH-DHPRS.
          </li>
          <li>
            Agree to keep the data files in a secure location where they cannot be accessed by
            unauthorized users.
          </li>
          <li>
            Agree to treat all data as confidential, and to make no effort to identify any
            individual, household, or enumeration area in the survey.
          </li>
          <li>
            Agree that no results will be published in which communities or individuals can be
            identified.
          </li>
          <li>
            Agree that the data will not be used for any marketing or commercial venture.
          </li>
          <li>
            Agree that the HPPDM microdata will not be re-distributed, either directly or within any
            tool/dashboard.
          </li>
          <li>
            Agree to submit a copy of any reports/publications resulting from using the data files
            to: charlesnzelu@yahoo.co.uk.
          </li>
        </ol>
          </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p {
  color: black;
}
</style>
