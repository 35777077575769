<template>
  <b-modal
    v-model="showDataRequestModal"
    body-class="border-0"
    centered
    class="border-0"
    hide-footer
    hide-header
    size="lg"
  >
    <b-container>
      <div>
        <b-row class="mb-2 ml-0" align-h="between">
          <h4 class="text-left text-primary">Dataset Request Confirmation</h4>
        </b-row>
        <b-row class="p-0" no-gutters align-h="start">
          <b-col cols="12" md="5" lg="2" class="mr-0">
            <p class="text-left font-weight-bold text-black-50">
              Name:
            </p>
          </b-col>
          <b-col cols="12" md="7" lg="10" class="ml-0">
            <p class="text-left">
              <span> {{ user.full_name }} </span>
            </p>
          </b-col>
        </b-row>
        <b-row class="p-0" no-gutters align-h="start">
          <b-col cols="12" md="5" lg="2" class="mr-0">
            <p class="text-left font-weight-bold text-black-50">
              Email:
            </p>
          </b-col>
          <b-col cols="12" md="7" lg="10" class="ml-0">
            <p class="text-left">
              <span> {{ user.email }} </span>
            </p>
          </b-col>
        </b-row>
        <b-row class="p-0" no-gutters align-h="start">
          <b-col cols="12" md="5" lg="2" class="mr-0">
            <p class="text-left font-weight-bold text-black-50">
              Co researcher:
            </p>
          </b-col>
          <b-col cols="12" md="7" lg="10" class="ml-0">
            <p class="text-left">
              <span> {{ projectselected.user.first_name }} </span>
              <span class="ml-1">
                {{ projectselected.user.last_name || '' }}
              </span>
            </p>
          </b-col>
        </b-row>
        <b-row class="p-0" no-gutters align-h="start">
          <b-col cols="12" md="5" lg="2" class="mr-0">
            <p class="text-left font-weight-bold text-black-50">
              Co researcher Email Address:
            </p>
          </b-col>
          <b-col cols="12" md="7" lg="10" class="ml-0">
            <p class="text-left">
              {{ projectselected.user.email }}
            </p>
          </b-col>
        </b-row>
        <b-row class="p-0" no-gutters align-h="start">
          <b-col cols="12" md="5" lg="2" class="mr-0">
            <p class="text-left font-weight-bold text-black-50">
              Project Title:
            </p>
          </b-col>
          <b-col cols="12" md="7" lg="10" class="ml-0">
            <p class="text-left">
              {{ projectselected.title }}
            </p>
          </b-col>
        </b-row>
      </div>
      <div class="mt-5 pb-1"></div>
      <div class="mt-n5">
        <p class="text-left mt-n4">
          Your request to download datasets for the above study has been received and is pending.
          You will receive an email notice after your request is reviewed. This process takes no
          more than 2 business days.
        </p>
        <!-- <p class="text-left pt-3 mb-5 pb-2">
          <span class="font-weight-bold text-black-50 mr-1">Pending Request(s)</span>
        </p> -->
      </div>

      <!-- <div class="mt-n5"></div>
      <b-table
        :bordered="false"
        thead-class="bg-secondary text-dark text-left borderless"
        :fields="fields"
        :items="items"
        tbody-class="text-left"
        responsive="sm"
      > -->
      <!-- add numbering to table -->
      <!-- <template #cell(SURVEYS)="">
          <i class="fas fa-check ml-4"></i>
        </template>
      </b-table> -->
      <div class="mt-2 ml-2 text-center">
        <base-button text="Done" @click="$router.push(`/dataset/my-dataset`)" width="10rem" />
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    showDataRequestModal: {
      type: Boolean,
      default: false,
    },
    projectselected: {
      type: Object,
      require: true,
    },
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  components: {},
  data() {
    return {
      fields: ['STATES', 'SURVEYS', 'SPA'],
      items: [
        {
          code: 'AB',
          STATES: 'Abia',
          surveys: true,
        },
        {
          code: 'AD',
          STATES: 'Adamawa',
        },
      ],
    };
  },
  methods: {},
};
</script>
