import axios from '@/plugin/axios';
import processError from './ProcessError';

const loginUrl = './auth/token/email';
const regUrl = './user/create';
const refreshUrl = './auth/token/refresh/';
const activateEndpoint = './account/activate_account';
const forgotEndpoint = './account/forgot_password';
// const resetEndpoint = './account/reset_password';

const Login = (LoginDetails) =>
  new Promise((resolve, reject) => {
    // console.log(user);
    axios
      .post(loginUrl, LoginDetails)
      .then((response) => {
        console.log(response);
        const { data } = response;
        if (data.access) {
          localStorage.setItem('access_token', data.access);
          localStorage.setItem('refresh_token', data.refresh);
        }
        resolve(response);
      })
      .catch((error) => {
        processError(error);
        reject(error);
      });
  });

const Registration = (RegistrationDetails) =>
  new Promise((resolve, reject) => {
    // console.log(user);
    axios
      .post(regUrl, RegistrationDetails)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        processError(error);
        reject(error);
      });
  });

const Logout = () =>
  new Promise((resolve) => {
    // console.log(user);
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    resolve();
  });

const getUserInfo = () =>
  new Promise((resolve, reject) => {
    console.log('fetching user info from API');
    axios
      .post(refreshUrl, {
        refresh: localStorage.getItem('refresh_token'),
      })
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        processError(error);
        reject(error);
      });
  });

const ActivateUser = (Token) =>
  new Promise((resolve, reject) => {
    // console.log(user);
    axios
      .post(activateEndpoint, {
        encoded_token: Token,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        processError(error);
        reject(error);
      });
  });

const ForgotPasssword = (Token) =>
  new Promise((resolve, reject) => {
    // console.log(user);
    axios
      .post(forgotEndpoint, {
        encoded_token: Token,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        processError(error);
        reject(error);
      });
  });

export default {
  Login,
  Registration,
  Logout,
  getUserInfo,
  ActivateUser,
  ForgotPasssword,
};
