import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// import SecureLS from 'secure-ls';
import { store as DATASET_STORE } from '../modules/dataset';
import { store as NGO_STORE } from '../modules/ngo';
import auth from '../modules/auth/store';

Vue.use(Vuex);
/*
 * The secureLs package help to encrypt to data store in the local storage
 *  it currently being commented out because its in the development phase
 */
// const ls = new SecureLS({ isCompression: false });

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    DATASET_STORE,
    NGO_STORE,
    auth,
    // main,
  },
  plugins: [
    /**
     *The createPersistedState plugin enable you store server
     * veux state in localStorage or any desired place for persistence
     *
     */
    createPersistedState({
      paths: ['auth'],
      // storage: {
      //   getItem: (key) => ls.get(key),
      //   setItem: (key, value) => ls.set(key, value),
      //   removeItem: (key) => ls.remove(key),
      // },
    }),
  ],
});
