<template>
  <div id="views-landing">
    <div class="card bg-dark text-white rounded-0">
      <img
        class="card-img"
        src="../../../assets/ngoimgs/landing page.png"
        alt="big-image"
        height="300"
        width="auto"
      />
      <div class="card-img-overlay">
        <div class="text-center">
          <img
            src="../../../assets/ngoimgs/Coat-of-arms.png"
            class="img-fluid"
            width="120"
            height="100"
          />
          <h1 class="text-white font-weight-normal custom-title">
            Welcome to Nigeria Health Partners' <br />Projects and Data Mapping Platform
          </h1>
          <h5 class="text-white font-italic mt-2">
            Mapping Health Partners, Projects and Data activities in Nigeria
          </h5>
        </div>
      </div>
    </div>

    <section id="news-update" class="my-4 p-0">
      <div class="row text-center no-gutters">
        <div class="col-md-1 bg-primary text-white">News</div>
        <div class="col-md-11">
          <marquee loop="" class="d-flex">
            <span
              class="mx-4 text-primary"
              style="cursor:pointer"
              v-for="(data, index) in news"
              :key="index"
              @click="loadLink(data.content)"
            >
              {{ data.heading }}
            </span>
          </marquee>
        </div>
      </div>
    </section>

    <!-- card section -->
    <section id="card-section" class="my-5 container">
      <div class="row text-center w-100">
        <div class="col-md-3">
          <Cards to="/dataset/project">
            <template v-slot:image>
              <img src="../../../assets/ngoimgs/book.png" class="img-fluid" width="50" />
            </template>
            <template v-slot:paragraph>
              Project Inventory
            </template>
            <template v-slot:subtitle>
              Explore and Submit all Health Projects
            </template>
            <template v-slot:subtitle2>
              <router-link to="dataset/project-inventory">- Explore projects <br /> </router-link>
              <router-link to="dataset/submit-project">- Submit projects</router-link>
            </template>
          </Cards>
        </div>
        <div class="col-md-3">
          <Cards to="/dataset">
            <template v-slot:image>
              <img src="../../../assets/ngoimgs/book.png" class="img-fluid" width="50" />
            </template>
            <template v-slot:paragraph>
              Data Inventory
            </template>
            <template v-slot:subtitle>
              Explore and Submit all Health Datasets
            </template>
            <template v-slot:subtitle2>
              <router-link to="dataset/dataset-inventory">- Explore datasets </router-link> <br />
              <router-link to="dataset/submit-dataset"> Submit datasets </router-link>
            </template>
          </Cards>
        </div>
        <div class="col-md-3">
          <Cards to="/dataset/stakeholders">
            <template v-slot:image>
              <img src="../../../assets/ngoimgs/social-care.png" class="img-fluid" width="50" />
            </template>
            <template v-slot:paragraph>
              Stakeholder Inventory
            </template>
            <template v-slot:subtitle>
              View list of all Stakeholders
            </template>
          </Cards>
        </div>
        <div class="col-md-3">
          <Cards to="ngo/landing">
            <template v-slot:image>
              <img src="../../../assets/ngoimgs/Untitled-2.png" class="img-fluid" width="50" />
            </template>
            <template v-slot:paragraph>
              NGO Inventory
            </template>
            <template v-slot:subtitle>
              Submit yearly reports, <br />
              View all registered NGOs, <br />
              View steps on how to <br />
              Register NGOs, <br />
              Forum
            </template>
          </Cards>
        </div>
      </div>
    </section>

    <hr class="my-5" />

    <!-- about us section -->
    <section class="container my-5" id="about-us">
      <h1 class="text-capitalize text-center my-5 font-weight-bold">about this site</h1>
      <p class="text-justify text-wrap custom-style" id="about-us-paragraph">
        This central web-based inventory has been developed by the Department of Health Planning,
        Research and Statistics (DHPRS) of the Federal Ministry of Health (FMoH) to compile all
        health data and intervention projects carried out by international partners &
        non-governmental organisations across all health domains in the country. The platform also
        captures details of Non Governmental Organizations (NGOs) that have been registered with the
        Federal Ministry of Health under the purview of the NGO branch at the Health Systems
        Strengthening division of the DHPRS-FMoH.
      </p>
      <div class="text-left">
        <router-link to="ngo/about"
          ><span class="py-5 text-primary"
            ><i class="fa fa-angle-down mx-1"></i>see more</span
          ></router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
import { news as NEWS } from '../config/api-modules/api.organization';
import Cards from './bits/bits.index';

export default {
  name: 'views-landing',
  components: {
    Cards,
  },
  data() {
    return {
      news: [],
    };
  },
  methods: {
    loadLink(link) {
      window.open(link);
    },
  },
  mounted() {
    NEWS().then((data) => {
      this.news = data.data.results;
    });
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
#about-us-paragraph {
  color: #090909;
  line-height: 20px;
}

.custom-style {
  font: normal normal normal 'Roboto';
}

.custom-title {
  font-family: 'Raleway';
}
</style>
