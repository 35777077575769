<template>
  <b-container>
    <div>
      <b-row class="mb-4 ml-1">
        <h4 class="text-left text-primary ml-n1">Request Datasets for your Project</h4>
      </b-row>
      <div class="mb-4 ml-1">
        <p class="text-left font-weight-bold text-black-50 ml-n1">
          Project Title
        </p>
        <p class="text-left text-black ml-n1 mt-n2">
          Childbirth record in Nigeria
        </p>
      </div>
      <div class="mb-4 ml-1">
        <p class="text-left font-weight-bold text-black-50 ml-n1">
          Select State
        </p>
        <p class="text-left text-black ml-n1 mt-n2">
          Please select a region to display the countries for which you want to request datasets
        </p>
      </div>
      <base-select width="25%" />
    </div>
    <div class="mt-5"></div>
    <b-table
      :bordered="false"
      thead-class="bg-secondary text-dark text-left borderless"
      :fields="fields"
      :items="items"
      tbody-class="text-left"
      responsive="sm"
    >
      <!-- add numbering to table -->
      <template #cell(SURVEYS)="data">
        <base-checkbox @click="selectSurvey(data)" class="mt-n5 ml-4" />
      </template>

      <!-- add numbering to table -->
      <template #cell(SPA)="data">
        <base-checkbox @click="selectSurvey(data)" class="mt-n5 ml-3" />
      </template>
    </b-table>
    <b-row class="ml-1 mt-5">
      <button type="submit" class="sharp ml-n1" @click="$router.push('/dataset/selected-state')">
        Save and Continue
      </button>
      <button type="button" class="save ml-0 ml-sm-n1">Back</button>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      fields: ['STATES', 'SURVEYS', 'SPA'],
      items: [
        {
          code: 'AB',
          STATES: 'Abia',
          surveys: true,
        },
        {
          code: 'AD',
          STATES: 'Adamawa',
        },
        {
          code: 'AK',
          STATES: 'AkwaIbom',
        },
        {
          code: 'AN',
          STATES: 'Anambra',
        },
        {
          code: 'BA',
          STATES: 'Bauchi',
        },
        {
          code: 'BY',
          STATES: 'Bayelsa',
        },
        {
          code: 'BE',
          STATES: 'Benue',
        },
        {
          code: 'BO',
          STATES: 'Borno',
        },
        {
          code: 'CR',
          STATES: 'CrossRiver',
        },
        {
          code: 'DE',
          STATES: 'Delta',
        },
        {
          code: 'EB',
          STATES: 'Ebonyi',
        },
        {
          code: 'ED',
          STATES: 'Edo',
        },
        {
          code: 'EK',
          STATES: 'Ekiti',
        },
        {
          code: 'EN',
          STATES: 'Enugu',
        },
        {
          code: 'GO',
          STATES: 'Gombe',
        },
        {
          code: 'IM',
          STATES: 'Imo',
        },
        {
          code: 'JI',
          STATES: 'Jigawa',
        },
        {
          code: 'KD',
          STATES: 'Kaduna',
        },
        {
          code: 'KN',
          STATES: 'Kano',
        },
        {
          code: 'KT',
          STATES: 'Katsina',
        },
        {
          code: 'KE',
          STATES: 'Kebbi',
        },
        {
          code: 'KO',
          STATES: 'Kogi',
        },
        {
          code: 'KW',
          STATES: 'Kwara',
        },
        {
          code: 'LA',
          STATES: 'Lagos',
        },
        {
          code: 'NA',
          STATES: 'Nassarawa',
        },
        {
          code: 'NI',
          STATES: 'Niger',
        },
        {
          code: 'OG',
          STATES: 'Ogun',
        },
        {
          code: 'ON',
          STATES: 'Ondo',
        },
        {
          code: 'OS',
          STATES: 'Osun',
        },
        {
          code: 'OY',
          STATES: 'Oyo',
        },
        {
          code: 'PL',
          STATES: 'Plateau',
        },
        {
          code: 'RI',
          STATES: 'Rivers',
        },
        {
          code: 'SO',
          STATES: 'Sokoto',
        },
        {
          code: 'TA',
          STATES: 'Taraba',
        },
        {
          code: 'YO',
          STATES: 'Yobe',
        },
        {
          code: 'ZA',
          STATES: 'Zamfara',
        },
        {
          code: 'FC',
          STATES: 'FCT',
        },
      ],

      descriptionOfStudy: '',
      collaboratingPartner: [{ cPartner: '' }],
    };
  },
  computed: {
    descriptionOfStudyComputedLength() {
      return this.descriptionOfStudy.length;
    },
  },
  methods: {
    addNewCollaboratingPartner() {
      this.collaboratingPartner.push({ cPartner: '' });
    },
    addNewAreaOfDataCollection() {
      this.areaOfDataCollection.push({ areaofdata: '' });
    },
    addNewFunder() {
      this.funders.push({ funder: '' });
    },
    addpdObjective() {
      this.pdObjectives.push({ objective: '' });
    },
    addExpectedOutcomes() {
      this.outcomes.push({ outcome: '' });
    },
    addAchievement() {
      this.achievements.push({ achievement: '' });
    },
    addChallenges() {
      this.challenges.push({ challenge: '' });
    },
  },
};
</script>

<style scoped>
button {
  display: inline-block;
  padding: 10px 29px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 10px 16px;
  outline: none;
}

.sharp {
  width: 12em;
  color: #fff;
  background-color: #30ab4c;
  border-color: #0b7022;
  border-radius: 4px;
}

.save {
  background-color: #fff;
  color: #30ab4c;
  border-color: #0b7022;
  border-radius: 0;
}

.save:hover,
.save:focus,
.save:active,
.save.active,
.open .dropdown-toggle.save {
  background-color: #ffffff;
  color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open .dropdown-toggle.sharp {
  border-radius: 0;
  color: #fff;
  background-color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

/***********************
  SHARP BUTTONS
************************/
.sharp {
  outline: none;
  border-radius: 0;
}

/***********************
  CUSTON BTN VALUES
************************/

button {
  padding: 14px 24px;
  outline: none;
  border: 0 none;
  /* font-weight: 700; */
  letter-spacing: 1px;
}
button:focus,
button:active:focus,
button.active:focus {
  outline: 0 none;
}

.sharp {
  background: #30ab4c;
  color: #ffffff;
}
.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open > .dropdown-toggle.sharp {
  background: #30ab4c;
}
.sharp:active,
.sharp.active {
  background: #45cc64;
  box-shadow: none;
}
.save:active,
.save.active {
  background: #d8f7e0;
  color: #30ab4c;
  box-shadow: none;
}
</style>
