import Routes from './routes';
import axios from '../../../../plugin/axios';

export const getProject = () => axios.get(Routes.mapping);

export const getGeoCoverage = () => axios.get(Routes.geocoverage);

export const getSinglePorject = (id) => axios.get(Routes.mapping + id);

export const addReport = (payload) => {
  const buildHeaders = { 'Content-Type': 'multipart/form-data' };
  const formData = new FormData();
  const info = JSON.stringify({
    year: payload.year,
    first_name: payload.first_name,
    last_name: payload.last_name,
    position: payload.position,
    email: payload.email,
    phone: payload.phone,
    achievements: payload.achievements,
    challenge: payload.challenge,
    summary: payload.summary,
  });
  // eslint-disable-next-line no-unused-expressions
  formData.append('document', payload.file);
  formData.append('start_date', payload.start_date);
  formData.append('end_date', payload.end_date);
  formData.append('name', payload.name);
  formData.append('details', info);
  formData.append('organization', payload.organization);
  formData.append('mapping_type', 1);
  return axios.post(Routes.mapping, formData, {
    headers: buildHeaders,
  });
};

export const getOrganizationProjects = (id) =>
  axios.get(Routes.mapping, {
    params: {
      organization: id,
    },
  });
