import store from '@/store';
import index from './views/index.vue';
import Login from './views/Login.vue';
import ResetPassword from './views/ForgotPassword.vue';
import Register from './views/Register.vue';
import VerifyResetPassword from './views/VerifyResetPassword.vue';
import AccountActivation from './views/AccountActivation.vue';
import fallback from './views/fallback.vue';

/**
 *
 * @param {array} routeRoles
 * @description
 * this check the user roles and checked for the router guard roles
 * for the user access
 */
function hasAccess(routeRoles) {
  const userRoleName = store.getters['auth/roles'];
  const found = routeRoles.some((role) => userRoleName.indexOf(role) >= 0);
  if (found) return true;
  return false;
}

/**
 *
 * @param {*} to
 * @param {*} from
 * @param {*} next
 * @description
 * The router guard is a Global router guard used in the root route of the project
 * The router checks for authentication and roles and permission
 */

const routerGuard = (to, from, next) => {
  console.log(to);
  console.log(from);
  // debugger;
  if (to.meta.requiresAuth) {
    if (store.getters['auth/isLoggedIn']) {
      // debugger;
      const { roles } = to.meta;
      if (roles && hasAccess(roles)) {
        next();
      } else {
        next('/');
      }
    } else {
      next('/auth');
    }
    // next();
  } else {
    next();
  }
};

const routes = [
  {
    // will match everything
    // This is add outside child route do it can be used
    // Globally
    path: '*',
    component: fallback,
  },
  {
    path: '/auth',
    component: index,
    children: [
      {
        path: '',
        component: Login,
      },
      {
        path: 'login',
        component: Login,
      },
      {
        path: 'register',
        component: Register,
      },
      {
        path: 'reset-password',
        component: ResetPassword,
      },
      {
        path: '/account/reset/:token',
        component: VerifyResetPassword,
      },
      {
        path: '/account/activate/:token',
        component: AccountActivation,
      },
    ],
  },
];

export default { routerGuard, routes };
