import AuthServices from './UserService';

export default {
  namespaced: true,
  state: {
    error: {
      message: '',
      show: false,
    },
    user: null,
    // token: localStorage.getItem('access_token') || '',
  },
  mutations: {
    show(state, payload) {
      state.error.message = payload.message;
      state.error.show = true;
    },
    noShow(state) {
      state.error.message = '';
      state.error.show = false;
    },
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async LOGIN({ commit }, payload) {
      const response = await AuthServices.Login(payload);
      const { status, data } = response;
      if (status === 200) {
        if (data.entity) {
          commit('setUser', data);
        }
      }
      return Promise.resolve(response);
    },
    async REGISTRATION({ commit }, payload) {
      const response = await AuthServices.Registration(payload);
      const { status, data } = response;
      if (status === 200) {
        if (data.entity) {
          commit('setUser', data);
        }
      }
      return Promise.resolve(response);
    },
    async LOGOUT({ commit }) {
      await AuthServices.Logout();
      /* 
      this prevents the user state 
      object from throwing an 
      "entity is null" error 
      after logging out
      */
      const data = {
        entity: null,
      };

      commit('setUser', data);
    },
    async GET_USER({ commit }) {
      const data = await AuthServices.getUserInfo();
      console.log(data);
      commit('setUser', data);
      return Promise.resolve(data);
    },
  },
  getters: {
    isLoggedIn: (state) => (state.user ? !!state.user.access : false),
    user: (state) => (state.user ? state.user.entity : null),
    roles: (state) => {
      if (state.user.entity !== null) {
        return state.user.entity.roles.map((item) => item.name);
      }
      return null;
    },
  },
};
