<template>
  <b-container>
    <div>
      <b-row class="mb-5 ml-0" align-h="between">
        <b-col cols="12" sm="4" class="mt-4 ml-n3">
          <h4 class="text-left text-primary">Explore Projects</h4>
        </b-col>
        <b-col
          cols="12"
          sm="3"
          class="text-right mt-n1 mr-n2 ml-n3 ml-sm-auto pl-4 justify-content-end d-flex"
        >
          <base-input
            prepend="fa fa-search"
            prependclass="mt-1"
            :filled="true"
            placeholder="Search"
            v-model="search"
            width="100%"
          />
        </b-col>
      </b-row>
      <b-row class="p-0" no-gutters align-h="between">
        <b-col cols="12" sm="9">
          <div v-if="loading" class="text-center loading-icon">
            <b-spinner v-if="loading" x-large></b-spinner>
          </div>
          <div v-else>
            <ProjectCard
              v-for="(project, index) in ComputedProjects"
              :key="index"
              :to="`/dataset/project/${project.id}`"
              :seemorelink="true"
              :date="project.created_at"
            >
              <template slot="body">
                <b-card-title class="text-left font-weight-bold text-black-50 h5">
                  {{ project.name }}
                </b-card-title>
                <b-card-text class="text-left text-black-50">
                  Capacity building for Local Governments and States’ Ministries, Departments and
                  Agencies (MDAs) responsible for rural water supply and sanitation intervention.
                </b-card-text>

                <div class="text-left mt-4">
                  <span class="font-weight-bolder text-black-50">Period of Implementation:</span>
                  <span class="ml-1 text-black-50"
                    >{{ getYear(project.start_date) }} - {{ getYear(project.end_date) }}</span
                  >
                </div>

                <div class="text-left mt-4">
                  <span class="font-weight-bolder text-black-50">Programs Area:</span>
                  <span
                    v-for="(program, index) in project.program_area"
                    :key="index"
                    class="ml-1 text-primary text-decoration-none"
                    ><span v-if="index !== project.program_area.length - 1"
                      >{{ program.name }},</span
                    >
                    <span v-else>{{ program.name }}</span></span
                  >
                </div>
              </template>
            </ProjectCard>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <p class="mt-1 mx-2">Row per page</p>
              <select id="zone" style="width: 50px; height: 40px;" v-model="perPage">
                <option value="3"> 3 </option>
                <option value="5"> 5 </option>
                <option value="10"> 10 </option>
              </select>
              <p class="mx-3 mx-4 h5">
                <!-- {{ currentPage * perPage }} -->
                <span>Page </span>{{ currentPage }} of
                {{ Math.ceil(computedData.length / perPage) }}
              </p>
              <b-pagination
                v-model="currentPage"
                :total-rows="computedData.length"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </b-col>
        <b-col cols="3" class="pl-5 d-none d-sm-block">
          <FilterSideBar @filterObjs="fetchDataBasedOnSelectedRequirements" />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import moment from 'moment';
import ProjectCard from '../../components/Shared/ProjectCard.vue';
// import Pagination from '../../components/Shared/Pagination.vue';
import FilterSideBar from '../../components/Shared/FilterSideBar.vue';

export default {
  components: {
    ProjectCard,
    FilterSideBar,
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      perPage: 3,
      loading: false,
      projects: { results: [] },
      search: '',
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    /**
     * computedData
     * * returns the total number of projects.
     */
    computedData() {
      return this.projects.results.filter((project) => {
        return project.name.toLowerCase().match(this.search.toLowerCase());
      });
    },

    /**
     * ComputedProjects
     * * returns this amount of projects to display per page
     */
    ComputedProjects() {
      return this.computedData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  methods: {
    /**
     * fectchData
     * * returns an array of projects
     * @param
     */
    async fetchData() {
      this.loading = true;
      try {
        const projects = await this.$dataset.getAllProjects();
        this.projects = projects.data;
        this.total = projects.data.results.length;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    /**
     *fetchDataBasedOnSelectedRequirements
     * * returns filtered projects based on sidebase requirements.
     * @param Obejcts sidebar emited object.
     */
    async fetchDataBasedOnSelectedRequirements(data) {
      this.currentPage = 1;
      this.loading = true;
      try {
        const filtered = await this.$dataset.filteredData(data);
        console.log(filtered);
        this.projects.results = filtered.data;
        this.total = filtered.data.length;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    getYear(date) {
      return moment(date).format('YYYY');
    },
  },
  /**
   * search
   * * will move back to the first page whenever users start type on search
   * ! help fix issue where items cannot be found if current page is not '1'
   */
  watch: {
    search() {
      this.currentPage = 1;
    },
  },
};
</script>
