<template>
  <b-container>
    <div v-if="loading" class="text-center loading-icon">
      <b-spinner v-if="loading" x-large></b-spinner>
    </div>
    <div v-else>
      <DetailsOverview :projectDetails="projectDetails[0]" />
      <div class="mt-5"></div>
      <div class="d-flex flex-row">
        <DetailsTabButton
          v-for="(tab, index) in tabs"
          :key="index"
          :tab="tab"
          :currentTab="currentTab"
          @changetab="changetab"
          class="mr-4"
        />
      </div>
      <Component
        v-if="currentTab != 'Microdata'"
        :is="currentTab"
        :projectDetails="projectDetails[0]"
      />
      <Modal :showModal="microdatadialog" />
      <b-row class="mt-5 ml-2">
        <base-button text="Back" @click="$router.push('/dataset/dataset-inventory')" width="8rem" />
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import DetailsOverview from '../../../components/ProjectDetails/DetailsOverview.vue';
import DetailsTabButton from '../../../components/ProjectDetails/DetailsTabButton.vue';
import ProjectDescription from '../../../components/ProjectDetails/ProjectDescription.vue';
import MethodologyTab from '../../../components/ProjectDetails/MethodologyTab.vue';
import OrganizationSponsorTab from '../../../components/ProjectDetails/OrganizationSponsorTab.vue';
import DocumentationTab from '../../../components/ProjectDetails/DocumentationTab.vue';
import ContactTab from '../../../components/ProjectDetails/ContactTab.vue';
import Modal from '../../../components/Shared/Modal.vue';

export default {
  components: {
    DetailsOverview,
    DetailsTabButton,
    ProjectDescription,
    MethodologyTab,
    OrganizationSponsorTab,
    DocumentationTab,
    ContactTab,
    Modal,
  },
  data() {
    return {
      loading: false,
      projectDetails: [],
      microdatadialog: false,
      currentTab: 'ProjectDescription',
      tabs: [
        {
          name: 'Description',
          tab: 'ProjectDescription',
        },
        {
          name: 'Methodology',
          tab: 'MethodologyTab',
        },
        {
          name: 'Organization and Sponsors',
          tab: 'OrganizationSponsorTab',
        },
        {
          name: 'Documentation',
          tab: 'DocumentationTab',
        },
        {
          name: 'Contact Information',
          tab: 'ContactTab',
        },
        {
          name: 'Get Microdata',
          tab: 'Microdata',
        },
      ],
    };
  },
  async mounted() {
    try {
      this.loading = true;
      const { data } = await this.$dataset.getDatasetDetails(this.$route.params.id);
      this.projectDetails = data;
      console.log(this.projectDetails);
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
  },
  methods: {
    showDataSetModal() {
      if (this.isLoggedIn) {
        this.$router.push(`/dataset/getmicrodata/${this.$route.params.id}`);
      }
      this.microdatadialog = true;
    },
    changetab(tab) {
      this.currentTab = tab;
      if (tab === 'Microdata') {
        console.log(tab);
        this.microdatadialog = true;
      } else {
        this.microdatadialog = false;
      }
    },
  },
};
</script>
