// import vuex from 'vuex';
import moment from 'moment';

export default class DatasetRequests {
  /**
   *
   * @param {import("axios").AxiosInstance} http
   * @param {Object} services
   * @param {*} store
   */
  constructor(http, services, store) {
    this.http = http;
    this.services = services;
    this.store = store;
    this.moment = moment;
  }

  // Error Handling for all
  processError(error) {
    let errmsg;

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { data, status } = error.response;
      // set errmsg based on error.response.status code
      switch (status) {
        case 400:
          errmsg = data.message || 'Invalid credentials';
          break;
        case 401:
          errmsg = data.message || data.error || 'Unauthorised request';
          if (data.error.includes('jwt') && data.error.includes('expired')) {
            this.store.dispatch('user/logOut');
          }
          break;
        case 404:
          errmsg = data.message || 'Requested params not found';
          break;
        case 422:
          errmsg = data.error[0].message || 'Invalid credentials';
          break;
        case 500:
          errmsg = data.error || 'Network error, try again';
          break;
        case 501:
          errmsg = data.error || 'Network error, try again';
          break;
        case 502:
          errmsg = data.error || 'Network error, try again';
          break;
        case 503:
          errmsg = data.error || 'Service unavailable, try again later';
          break;
        default:
          // eslint-disable-next-line operator-linebreak
          errmsg =
            // eslint-disable-next-line operator-linebreak
            data.message ||
            // eslint-disable-next-line operator-linebreak
            data.error.message ||
            // eslint-disable-next-line operator-linebreak
            data.error[0].message ||
            'An error occurred, try again';
          break;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // and an instance of http.ClientRequest in node.js
      errmsg = 'Internet connection error';
    } else {
      // Something happened in setting up the request that triggered an Error
      errmsg = error.message || 'Unknown error, try again';
    }

    // Display error message in a snackbar
    this.store.commit('snackbar/show', { icon: 'error', text: errmsg });
  }

  // main apis starts
  formatDate(date, format) {
    return this.moment(date).format(format);
  }

  async getAllGeoCoverage(pagesize = 1000) {
    try {
      const data = await this.http.get(`${this.services.getAllGeoCoverage}?page_size=${pagesize}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllHealthAreas(pagesize = 1000) {
    try {
      const data = await this.http.get(`${this.services.getAllHealthAreas}?page_size=${pagesize}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllProgramAreas(pagesize = 1000) {
    try {
      const data = await this.http.get(`${this.services.getAllProgramAreas}?page_size=${pagesize}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllOrganization(pagesize = 1000) {
    try {
      const data = await this.http.get(`${this.services.getAllOrganization}?page_size=${pagesize}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllSensitivity(pagesize = 1000) {
    try {
      const data = await this.http.get(`${this.services.getAllSensitivity}?page_size=${pagesize}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getOwnerType(pagesize = 1000) {
    try {
      const data = await this.http.get(`${this.services.getOwnerType}?page_size=${pagesize}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getPopulationUnit(pagesize = 1000) {
    try {
      const data = await this.http.get(`${this.services.getPopulationUnit}?page_size=${pagesize}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }
  // main apis ends

  // for datasets
  async getMyDatasets(userId, page = 1) {
    try {
      const data = await this.http.get(
        `${this.services.requestDataset}?user=${userId}&page=${page}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  // for datasets
  async createDataset(datasetData) {
    try {
      const data = await this.http.post(this.services.getAllDataset, datasetData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async editDataset(id, datasetData) {
    try {
      const data = await this.http.patch(`${this.services.getAllDataset}${id}/`, datasetData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async associateDatasetOrganizations(datasetData) {
    try {
      const data = await this.http.post(this.services.associateDatasetOrganizations, datasetData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getDatasets(page = 1, pagesize = 10) {
    try {
      const data = await this.http.get(
        `${this.services.getAllDataset}?page=${page}&page_size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async requestDataset(requestInfo) {
    try {
      const data = await this.http.post(this.services.requestDataset, requestInfo);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getUserCreatedProjects(id, pageSize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getUserCreatedProjects}${id}&page_size=${pageSize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async createProjectForDatasetRequest(createdata) {
    try {
      const data = await this.http.post(this.services.createProjectToGetDataset, createdata);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getDatasetDetails(id) {
    try {
      const data = await this.http.get(`${this.services.getDatasetDetails}/${id}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  // for stakeholders
  async getAllStakeholdersBranch(pageSize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllStakeholdersBranch}?page_size=${pageSize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllStakeholdersCategory(pageSize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllStakeholdersCategory}?page_size=${pageSize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllStakeholders() {
    try {
      const data = await this.http.get(this.services.getAllStakeholders);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllStakeholdersDivision() {
    try {
      const data = await this.http.get(this.services.getAllStakeholdersDivision);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllStakeholdersDivisionById(id) {
    try {
      const data = await this.http.get(`${this.services.getAllStakeholdersDivisionById}/${id}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllStakeholderActivityDetailsById(id) {
    try {
      const data = await this.http.get(
        `${this.services.getAllStakeholderActivityDetailsById}/${id}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllStakeholdersDivisionActivityById(url) {
    try {
      const data = await this.http.get(
        `${this.services.getAllStakeholdersDivisionActivityById}${url}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  // apis for projects
  async associateProjectOrganizations(projectData) {
    try {
      const data = await this.http.post(this.services.associateProjectOrganizations, projectData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async submitProject(projectData) {
    try {
      const data = await this.http.post(this.services.submitProject, projectData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async editProject(id, projectData) {
    try {
      const data = await this.http.patch(`${this.services.submitProject}${id}/`, projectData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllProjects(pagesize = 100000000000) {
    try {
      const data = await this.http.get(`${this.services.getAllProject}?page_size=${pagesize}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getProjectDetails(id) {
    try {
      const data = await this.http.get(`${this.services.getProjectDetails}/${id}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getStates() {
    try {
      const data = await this.http.get(
        `${this.services.getAllGeoCoverage}?level=State&page_size=${37}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async filteredData(payload) {
    try {
      const data = await this.http.post(`${this.services.filterRoute}`, payload);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }
}
