<template>
  <div id="ngo-registered">
    <div class="container my-2">
      <h4 class="text-primary mb-4 custom-header">Registered NGOs</h4>
      <div class="row mb-4">
        <div class="col-md-2 mt-4">
          <select id="year" v-model="ByYear">
            <option value="">All Years</option>
            <option v-for="(year, index) in generateYear()" :key="index">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="col-md-2 mt-4">
          <select id="state" v-model="ByState">
            <option value="">All States</option>
            <option v-for="(state, index) in states" :key="index">
              {{ state }}
            </option>
          </select>
        </div>
        <div class="col-md-3 mt-4">
          <select id="zone" v-model="ByZone">
            <option value="">Geographical Zones</option>
            <option v-for="(zone, index) in zones" :key="index">
              {{ zone }}
            </option>
          </select>
        </div>
        <div class="col-md-5">
          <base-input
            prepend="fa fa-search"
            prependclass="mt-1"
            :filled="true"
            placeholder="Search"
            width="100%"
            v-model="filter"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <b-table
        thead-class="bg-success text-dark text-left borderless"
        :hover="false"
        :bordered="false"
        responsive="true"
        :items="filteredData"
        :fields="fields"
        :busy="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        primary-key="id"
        show-empty
        ref="table"
      >
        <template #empty="scope">
          <h4 class="text-primary text-center">{{ scope.emptyText }}</h4>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(action)="row">
          <b-button
            id="view-modal"
            v-b-modal.modal-center
            class="btn-sm bg-primary text-white rounded-0"
            @click="preview(row.item)"
            >view more</b-button
          >
        </template>

        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>

        <template #head(action)>
          <p></p>
        </template>

        <template #head()="data">
          <span class="font-weight-bold table-header-style">
            {{ data.label }}
          </span>
        </template>

        <template #cell()="data">
          <span class="custom-row h6">{{ data.value }}</span>
        </template>
        <template #cell(details.status)="data">
          <span
            style="color: white !important"
            class="custom-row h6 badge"
            :class="[data.value ? 'badge-primary' : 'badge-danger']"
            >{{ data.value ? 'active' : 'inactive' }}</span
          >
        </template>
        <template #head(id)="data">
          <span class="h6 font-weight-bold">{{ data.label }}</span>
        </template>
      </b-table>
      <div class="d-flex justify-content-end container">
        <p class="mt-1 mx-2">Row per page</p>
        <select id="zone" style="width: 50px; height: 40px" v-model="perPage">
          <option value="5">5</option>
          <option value="10">10</option>
        </select>
        <p class="mx-2 mt-2">{{ currentPage }} - {{ perPage }} of {{ filteredData.length }}</p>
        <b-pagination
          v-model="currentPage"
          :total-rows="filteredData.length"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>

    <RegisteredNgo v-if="previewData != null" :data="previewData" />
  </div>
</template>

<script>
import { table } from '../../config/config.table';
import RegisteredNgo from '../modals/ng-registered-model.vue';
import { getOrganizations as ORGANIZATIONS } from '../../config/api-modules/api.organization';

export default {
  name: 'ngo-registered',
  components: {
    RegisteredNgo,
  },
  data() {
    return {
      zones: [
        'North Central',
        'North East',
        'North West',
        'South East',
        'South South',
        'South West',
      ],
      state: '',
      filter: '',
      ByState: '',
      ByZone: '',
      ByYear: '',
      states: [
        'Niger',
        'Kogi',
        'Benue',
        'Plateau',
        'Nasarawa',
        'Kwara',
        'FCT',
        'Bauchi',
        'Borno',
        'Taraba',
        'Adamawa',
        'Gombe',
        'Yobe',
        'Zamfara',
        'Sokoto',
        'Kaduna',
        'Kebbi',
        'Katsina',
        'Kano',
        'Jigawa',
        'Enugu',
        'Imo',
        'Ebonyi',
        'Abia',
        'Anambra',
        'Bayelsa',
        'Akwa Ibom',
        'Edo',
        'Rivers',
        'Cross River',
        'Delta',
        'Oyo',
        'Ekiti',
        'Osun',
        'Ondo',
        'Lagos',
        'Ogun',
      ],
      perPage: 5,
      total: 0,
      rows: 2,
      currentPage: 1,
      isBusy: table.isBusy,
      fields: table.fields,
      items: [],
      previewData: null,
    };
  },

  computed: {
    filteredData() {
      const filtered = this.items.filter((item) => {
        return (
          item.details.state.toLowerCase().indexOf(this.ByState.toLowerCase()) > -1 &&
          item.details.geo_zone.toLowerCase().indexOf(this.ByZone.toLowerCase()) > -1 &&
          item.details.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 &&
          item.details.year.toString().indexOf(this.ByYear.toString()) > -1
        );
      });
      console.trace(filtered);
      return filtered;
    },
  },

  methods: {
    preview(data) {
      this.previewData = data;
    },
    generateYear() {
      let startYear = 1940;
      const currentYear = new Date().getFullYear();
      const years = [];
      startYear = startYear || 1980;
      while (startYear <= currentYear) {
        // eslint-disable-next-line no-plusplus
        years.push(startYear++);
      }
      return years;
    },

    get_data() {
      this.isBusy = true;
      ORGANIZATIONS()
        .then((data) => {
          this.items = data.data.results;
          this.total = data.data.results.length;
          console.trace(this.items);
        })
        .catch(() => {
          this.items = [];
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },

  mounted() {
    this.get_data();
  },
};
</script>

<style scoped>
* {
  font-family: 'Open Sans';
}
.custom-header {
  font: normal normal normal 'Open Sans';
  /* font-family: 'Open Sans';
  font-weight: 400; */
}

.custom-row {
  font: normal normal normal 1rem 'Open Sans';
  color: #707070 !important;
  opacity: 1;
}

.table-header-style {
  font: normal normal bold 0.9394rem 'Open Sans';
}
.select-style {
  height: 35px;
}
.input-group-text {
  border: none !important;
  background-color: #efe7e74d !important;
}

.form-control {
  background-color: #efe7e74d !important;
  border-left-width: none !important;
  outline: none i !important;
  border-left: none !important;
}

.input-group {
  border: 1px solid #707070;
  width: 350px !important;
}

h4 {
  font-family: 'Open Sans';
}
select,
select.form-control {
  background: transparent;
  border: none;
  border: 1px solid #707070;
  -webkit-box-shadow: none;
  outline: none;
  box-shadow: none;
  border-radius: 0;
}

select {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;
  font-family: inherit;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  color: #707070;
  outline: none;
}

select:focus {
  background: transparent;
  border: none;
  border: 1px solid #707070;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  outline: none;
}

select:focus,
select.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.inputField,
select.form-control {
  background: #ffffff;
  border: none;
  border: 1px solid #707070;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.inputField:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #707070;
}
</style>
