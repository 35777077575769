<template>
  <b-container>
    <div>
      <b-row class="mb-4 ml-1">
        <h4 class="text-left text-primary ml-n1">Create New Project</h4>
      </b-row>
      <b-row class="mb-4 ml-1">
        <p class="text-left text-black ml-n1">Childbirth record in Nigeria</p>
      </b-row>
      <ValidationObserver v-slot="{ passes }">
        <form @submit.prevent="passes(createProject)">
          <base-input
            v-model="createData.title"
            name="Project Title*"
            rules="required"
            label="Project Title*"
          />
          <p class="text-left font-weight-bold text-black-50 mb-0 mt-5 pt-4">
            Co-researchers
          </p>
          <b-row class="mb-n2">
            <b-col cols="6">
              <base-input
                v-model="createData.co_researchers[0].name"
                width="98%"
                name="Name"
                rules="required"
                label="Name"
              />
            </b-col>
            <b-col cols="6">
              <base-input
                v-model="createData.co_researchers[0].email"
                name="Email"
                width="96%"
                rules="required"
                label="Email*"
              />
            </b-col>
          </b-row>
          <div class="mt-5 pt-2"></div>
          <base-textarea
            label="Description of Study*"
            v-model="createData.description"
            name="Description of Study"
            description="Please provide information on target population of intervention"
            rules="required|min:10|max:2500"
          />
          <div class="text-left mt-3">
            <span>You have entered</span>
            <p class="mb-n1">{{ descriptionOfStudyComputedLength }}</p>
            <span>number of characters. (Minimum: 10; Maximum: 2500)</span>
          </div>
          <b-row class="ml-1 mt-5">
            <button type="submit" class="sharp ml-n1">
              <b-spinner v-if="loading" small></b-spinner>
              <span v-else>Save and Continue</span>
            </button>
            <button type="button" class="save ml-0 ml-sm-n1">Cancel</button>
          </b-row>
        </form>
      </ValidationObserver>
    </div>
    <DataRequestConfirmationModal
      :showDataRequestModal="showDataRequestModal"
      :projectselected="projectSelected"
    />
  </b-container>
</template>

<script>
import { toast } from '@/plugin/customToast';
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import DataRequestConfirmationModal from '../../../components/ProjectDetails/DataRequestConfirmationModal.vue';

export default {
  components: {
    ValidationObserver,
    DataRequestConfirmationModal,
  },
  data() {
    return {
      loading: false,
      showDataRequestModal: false,
      projectSelected: {},
      createData: {
        title: '',
        co_researchers: [{ name: '', email: '' }],
        description: '',
        user: null,
      },
      collaboratingPartner: [{ cPartner: '' }],
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    descriptionOfStudyComputedLength() {
      return this.createData.description.length;
    },
  },
  methods: {
    async createProject() {
      this.loading = true;
      try {
        this.createData.user = this.user.id;
        const { data } = await this.$dataset.createProjectForDatasetRequest(this.createData);
        this.projectSelected = {
          title: data.title,
          user: {
            email: data.co_researchers[0].email,
            first_name: data.co_researchers[0].name,
            last_name: '',
          },
        };
        toast.show({
          type: 'SUCCESS',
          message: 'Project Created Sucessfully!',
        });
        // submit data request
        const dataToRequest = {
          // details: null,
          approved: false,
          user: this.user.id,
          project: data.id,
          dataset: this.$route.params.id,
        };
        const requestD = await this.$dataset.requestDataset(dataToRequest);
        console.log('data', data, requestD);
        this.createData.title = '';
        this.createData.description = '';
        this.createData.user = null;
        this.createData.co_researchers = [{ name: '', email: '' }];
        this.showDataRequestModal = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
      // this.$router.push('/dataset/select-state');
    },
  },
};
</script>

<style scoped>
button {
  display: inline-block;
  padding: 10px 29px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 10px 16px;
  outline: none;
}

.sharp {
  width: 12em;
  color: #fff;
  background-color: #30ab4c;
  border-color: #0b7022;
}

.save {
  background-color: #fff;
  color: #30ab4c;
  border-color: #0b7022;
  border-radius: 0;
}

.save:hover,
.save:focus,
.save:active,
.save.active,
.open .dropdown-toggle.save {
  background-color: #ffffff;
  color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open .dropdown-toggle.sharp {
  border-radius: 0;
  color: #fff;
  background-color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

/***********************
  SHARP BUTTONS
************************/
.sharp {
  outline: none;
  border-radius: 0;
}

/***********************
  CUSTON BTN VALUES
************************/

button {
  padding: 14px 24px;
  outline: none;
  border: 0 none;
  /* font-weight: 700; */
  letter-spacing: 1px;
}
button:focus,
button:active:focus,
button.active:focus {
  outline: 0 none;
}

.sharp {
  background: #30ab4c;
  color: #ffffff;
}
.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open > .dropdown-toggle.sharp {
  background: #30ab4c;
}
.sharp:active,
.sharp.active {
  background: #45cc64;
  box-shadow: none;
}
.save:active,
.save.active {
  background: #d8f7e0;
  color: #30ab4c;
  box-shadow: none;
}
</style>
