<template>
  <b-navbar variant="white" type="light" class="justify-content-between">
    <b-navbar-brand to="/" class="d-flex"
      ><img src="@/assets/ministryOfHealthLogo.png" style="height: 3rem" />
      <div class="ml-1">
        <p class="text-primary text-left h4">
          Health Partners' Projects & Data Mapping Platform
        </p>
        <p class="text-black mt-n2 small text-lef mb-1">
          Mapping Health Partners' Projects and Data-related activities in Nigeria
        </p>
      </div>
    </b-navbar-brand>

    <div class="mt-4">
      <ul class="d-inline-flex list-unstyled">
        <li
          v-for="(nav, index) in navLinks"
          :key="index"
          class="border-dark list-unstyled px-3 text-decoration-none"
          :class="[navLinks.length - 1 != index ? 'border-right' : '']"
        >
          <router-link :to="nav.link" class="text-dark"> {{ nav.title }}</router-link>
        </li>
      </ul>
    </div>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      navLinks: [
        {
          title: 'Home',
          link: '/',
        },
        {
          title: 'About',
          link: '/ngo/about',
        },
        {
          title: 'FAQs',
          link: '/ngo/faq',
        },
        {
          title: 'Contact Us',
          link: '/ngo/contact-us',
        },
      ],
    };
  },
};
</script>

<style scoped>
.lognav {
  /* padding-bottom: 3rem; */
}
</style>
