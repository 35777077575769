<template>
  <div class="container my-5">
    <h3 class="text-primary font-weight-normal my-4 custom">Contact us</h3>

    <p class="text-justify">
      Department of Health Planning, Research and Statistics, Federal Ministry of Health, 11th
      floor, New Federal Secretariat, Complex, Phase III, Central Business District, FCT Abuja,
      Nigeria.
    </p>

    <section>
      <p>Mr. Martins Otuama (+2347031992732)- Monitoring and Evaluation (M&E) Division</p>
      <!-- <p>Dr. Ugo Omede (+2348126157965)- International Cooperation (IC) Division</p> -->
      <p>
        Mr. Micheal Abbah (+2348095670062; +234805635505)- Head NGO coordination branch
      </p>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.custom {
  font-family: 'Open Sans';
}

p {
  font-family: 'Roboto';
  font-size: 1.125rem;
  letter-spacing: 0px;
  word-spacing: 0px;
  font-weight: normal;
}
</style>
