const dataset = '/dataset/crud';
const project = '/project/crud';
const main = '/main/crud';
const stakeholders = '/stakeholder/crud';

export default {
  // for main apis
  getAllHealthAreas: `${main}/health_system_area/`,
  getAllProgramAreas: `${main}/program_area/`,
  getAllGeoCoverage: `${main}/geo_coverage/`,
  getAllOrganization: `${main}/organization/`,
  getAllSensitivity: `${main}/mapping_sensitivity/`,
  getOwnerType: `${main}/mapping_owner/`,
  getPopulationUnit: `${main}/population_unit/`,
  getAllStakeholdersBranch: `${stakeholders}/stakeholder_branch/`,

  // for datasets
  getAllDataset: `${dataset}/dataset/`,
  associateDatasetOrganizations: `/dataset/partners_associate`,
  getDatasetDetails: '/dataset/detailed',
  createProjectToGetDataset: `${dataset}/dataset_project/`,
  requestDataset: `${dataset}/dataset_data_request/`,
  getUserCreatedProjects: `${dataset}/dataset_project/?user=`,

  // for stakeholders
  getAllStakeholders: `${stakeholders}/stakeholder`,
  getAllStakeholdersDivision: `${stakeholders}/stakeholder_division`,
  getAllStakeholdersDivisionById: `${stakeholders}/division`,
  getAllStakeholdersCategory: `${stakeholders}/stakeholder_category/`,
  getAllStakeholdersDivisionActivityById: `${stakeholders}/stakeholder_activity`,
  getAllStakeholderActivityDetailsById: `${stakeholders}/stakeholder_activity`,

  // for project
  getAllProject: `${project}/project/`,
  getProjectDetails: '/project/detailed',
  submitProject: `${project}/project/`,
  associateProjectOrganizations: `/project/partners_associate`,

  // filter route
  filterRoute: `/project/filter`,
};
