<template>
  <b-container>
    <div>
      <ProjectHeroBanner
        :image="require('@/assets/dataset/images/health-project.png')"
        heading="Nigeria’s Health Project Inventory"
        buttonOneLink="/dataset/project-inventory"
        buttonTwoLink="/dataset/submit-project"
        buttonOneText="Explore Projects"
        buttonTwoText="Submit Projects"
      />
      <div class="mt-5">
        <p class="text-center font-weight-bold h5">
          About Health Project Inventory
        </p>
        <p class="text-left pt-3">
          The Department of Health Planning, Research and Statistics (DPRS) of the Federal Ministry
          of Health has developed this web based inventory containing information of previous and
          ongoing health related data collection activities carried out in the country over the last
          ten years. This will help create clear data flow channels between stakeholders in the
          health sector, avoid wastage of resources by enhancing coordination between stakeholders,
          and reduce duplication in data collection.
        </p>
        <div class="text-left">
          <b-link v-if="!seemore" class="text-left text-decoration-none" @click="seemore = true">
            <i class="fas fa-chevron-down text-primary mr-1"></i>
            See more</b-link
          >
          <b-link v-else class="text-left text-decoration-none" @click="seemore = false">
            <i class="fas fa-chevron-up text-primary mr-1"></i>
            See less</b-link
          >
        </div>
        <div v-if="seemore">
          <p class="text-center font-weight-bold h5 mt-5">
            Introduction
          </p>
          <p class="text-left pt-3">
            Multiple partners (multiple and bilateral donors, non-governmental organizations, faith
            based organizations and the private sector) carry out multiple vertical health
            intervention programs in the country with the overall goal of improving the health and
            well-being of Nigerians. To build an effective monitoring system to coordinate health
            partners, there is a need to document their activities and objectives.
            <br />
            <br />
            In response to this need, this web based health partners’ inventory has been developed
            by the Department of Health Planning, Research and Statistics (DPRS) to update our data
            on all health interventions carried out in the country from 2015. This will help provide
            insight into the current situation of health partners and their projects in the country,
            and ensure that funds provided to carry out health projects are not targeted at a few
            disease programs or a narrow set of interventions.
          </p>
          <p class="text-center font-weight-bold h5 mt-5">
            Objectives
          </p>
          <p class="text-left pt-3">
            The objectives of this web based health partners’ inventory are:
          </p>
          <ol class="ml-n4">
            <li class="text-left">
              To have an accessible online database of all health partners in the country
            </li>
            <li class="text-left">
              To have a holistic view of health projects carried out by health partners within the
              country.
            </li>
            <li class="text-left">
              To identify gaps in health programs and interventions, and reschedule priorities for
              future planning.
            </li>
            <li class="text-left">
              To enhance harmonization and information sharing among health partners for a more
              coordinated, coherent and efficient health partner support system.
            </li>
            <li class="text-left">
              To monitor health partners and ensure the alignment of their activities with the
              country’s agenda and priorities.
            </li>
          </ol>
        </div>
        <p class="text-center font-weight-bold h5 mt-5">
          Health Projects In Nigeria
        </p>
        <b-row class="p-0 mt-4" no-gutters align-h="between">
          <b-col cols="12" sm="9">
            <div class="map">
              <highcharts
                :constructorType="'mapChart'"
                class="hc"
                :options="options"
                ref="chart"
              ></highcharts>
            </div>
          </b-col>
          <b-col cols="3" class="pl-5 d-none d-sm-block">
            <FilterSideBar :showsideborder="false" :accessLevel="false" />
          </b-col>
        </b-row>
      </div>
    </div>
  </b-container>
</template>

<script>
import nigerianMap from '../../components/nigeria';
import ProjectHeroBanner from '../../components/Layout/ProjectHeroBanner.vue';
import FilterSideBar from '../../components/Shared/FilterSideBar.vue';
import mapdata from '../../components/map';

export default {
  components: {
    ProjectHeroBanner,
    FilterSideBar,
  },
  data() {
    return {
      options: {
        chart: {
          map: nigerianMap,
          height: `${(9 / 13) * 100}%`,
          backgroundColor: '#e8f5eb77',
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: 'top',
          },
        },
        colorAxis: {
          min: 0,
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        series: [
          {
            data: mapdata,
            name: 'Number of Project',
            states: {
              hover: {
                color: 'green',
              },
            },
            dataLabels: {
              enabled: true,
              format: '{point.name}',
            },
          },
        ],
      },
      seemore: false,
      sections: [
        {
          title: 'Research Knowledge and Management',
          description:
            'Coordinate and promote all health research for efficient health system development and knowledge sharing',
          link: '/dataset/stakeholder',
        },
        {
          title: 'Policy and Planning',
          description:
            'Coordinate health policy and plan formulation, review and priority settings',
          link: '/',
        },
        {
          title: 'International Cooperation',
          description:
            'Coordinate international cooperation on health and collaborations with development partners, NGOs and other health actor',
          link: '/',
        },
        {
          title: 'Management and Evaluation Division',
          description:
            'Coordinate monitoring and evaluation of the health system and its performance',
          link: '/',
        },
        {
          title: 'Health System Strengthening',
          description: 'Plan for human resource for health development',
          link: '/',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
ul.dash {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}
ul.dash > li:before {
  display: inline-block;
  content: '-';
  width: 1em;
  margin-left: -1em;
}

.map {
  background-color: #ecf5ef;
  border: 1px solid #707070;
  padding: 3rem;
}
</style>
