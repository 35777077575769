<template>
  <div id="ngo-admin-add">
    <section class="container mt-2 mb-5">
      <h4 class="text-primary text-uppercase font-weight-normal">add new ngo</h4>
      <p>
        Please complete the following questions by selecting the answers(s) of your choice or
        writing in the space provided
      </p>
      <ValidationObserver v-slot="{ passes }">
        <form @submit.prevent="passes(submit(form))">
          <base-input
            height="3rem"
            placeholder="Name of NGO"
            width="100%"
            rules="required"
            v-model="form.name"
            label="Name of NGO"
            name="name of NGO"
          />
          <p class="text-left font-weight-bold text-dark mb-1 mt-4">Program areas</p>
          <div class="row">
            <div class="text-center ml-4" v-if="program_area_loader">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="col-md-3" v-for="(input, index) in programs" :key="index" v-else>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input m-1 ml-2"
                  type="checkbox"
                  id="inlineCheckbox1"
                  :value="input.id"
                  v-model="form.program_area"
                />
                <label class="form-check-label" for="inlineCheckbox1">{{ input.name }}</label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3 my-2">
              <base-select
                height="3rem"
                :items="generateYear()"
                placeholder="Year"
                width="100%"
                v-model="form.year"
                rule="required"
                label="Year of registration"
              />
            </div>
          </div>
          <div class="form-group">
            <p class="font-weight-bold">Name of contact person</p>
            <div class="form-row">
              <div class="col-md-4">
                <base-input
                  height="3rem"
                  placeholder="First name"
                  width="100%"
                  rule="required"
                  v-model="form.contact_person_first_name"
                  label="First name"
                />
              </div>
              <div class="col-md-4">
                <base-input
                  height="3rem"
                  placeholder="Last name"
                  width="100%"
                  rule="required"
                  v-model="form.contact_person_last_name"
                  label="Last name"
                />
              </div>
            </div>
          </div>
          <base-input
            height="3rem"
            placeholder="Phone number of contact person"
            width="50%"
            v-model="form.contact_person_phone"
            rule="required"
            label="Phone number of contact person"
          />
          <base-input
            height="3rem"
            placeholder="Email"
            width="50%"
            rules="required|email"
            v-model="form.email"
            label="Email"
          />
          <base-input
            height="3rem"
            placeholder="Address"
            width="50%"
            rules="required"
            v-model="form.address"
            label="Address"
          />
          <base-select
            height="3rem"
            :items="['active', 'suspended']"
            placeholder="Status"
            width="30%"
            v-model="form.status"
            label="Status"
            rule="required"
          />
          <base-select
            height="3rem"
            :items="states"
            placeholder="State"
            width="30%"
            rule="required"
            v-model="form.state"
            label="State"
          />
          <base-select
            height="3rem"
            :items="zones"
            placeholder="Geopolitical zone"
            width="30%"
            rule="required"
            name="Geopolitical zone"
            v-model="form.geo_zone"
            label="Geopolitical zone"
          />
          <button class="text-white bg-primary p-2 custom-button mt-4" :disabled="loader">
            <div class="spinner-border" role="status" v-if="loader">
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else>Submit</span>
          </button>
        </form>
      </ValidationObserver>
    </section>
  </div>
</template>

<script>
import {
  getProgramArea as GETPROGRAMS,
  addOrganization as ADD_ORGANIZATION,
  // patchOrganization as UPDATAE_NGO,
} from '../../config/api-modules/api.organization';
import zones from '../../config/states_and_zones';

export default {
  name: 'ngo-admin-add',
  data() {
    return {
      form: {
        program_area: [],
        geo_zone: null,
      },
      zones: [
        'North Central',
        'North East',
        'North West',
        'South East',
        'South South',
        'South West',
      ],
      states: [
        'Niger',
        'Kogi',
        'Benue',
        'Plateau',
        'Nasarawa',
        'Kwara',
        'FCT',
        'Bauchi',
        'Borno',
        'Taraba',
        'Adamawa',
        'Gombe',
        'Yobe',
        'Zamfara',
        'Sokoto',
        'Kaduna',
        'Kebbi',
        'Katsina',
        'Kano',
        'Jigawa',
        'Enugu',
        'Imo',
        'Ebonyi',
        'Abia',
        'Anambra',
        'Bayelsa',
        'Akwa Ibom',
        'Edo',
        'Rivers',
        'Cross River',
        'Delta',
        'Oyo',
        'Ekiti',
        'Osun',
        'Ondo',
        'Lagos',
        'Ogun',
      ],
      loader: false,
      programs: [],
      zone: zones,
      program_area_loader: false,
      total: null,
    };
  },
  methods: {
    generateYear() {
      let startYear = 1940;
      const currentYear = new Date().getFullYear();
      const years = [];
      startYear = startYear || 1980;
      while (startYear <= currentYear) {
        // eslint-disable-next-line no-plusplus
        years.push(startYear++);
      }
      return years;
    },
    submit(payload) {
      debugger;
      this.loader = true;
      ADD_ORGANIZATION(payload)
        .then((e) => {
          console.log(e);
          this.$toast.show({
            type: 'SUCCESS',
            message: `${payload.name} has been added`,
          });
          this.$router.push('/ngo/admin');
          /**
           *
           */
          // const newJec = Object.assign(e.data.details, { roles: [3] });
          // console.log(newJec);
          // debugger;
          // UPDATAE_NGO(e.data.id, { roles: [3] })
          //   .then((et) => {
          //     console.log(et);
          //     this.$toast.show({
          //       type: 'SUCCESS',
          //       message: `${payload.name} has been added`,
          //     });
          //     this.$router.push('/ngo/admin');
          //   })
          //   .catch((el) => {
          //     console.log(el);
          //   });
          // this.$toast.show({
          //   type: 'SUCCESS',
          //   message: `${payload.name} has been added`,
          // });
        })
        .catch(() => {
          this.$toast.show({
            type: 'ERROR',
            message: `Unable to add ${payload.name}`,
          });
        })
        .finally(() => {
          this.loader = false;
        });
    },

    getPrograms(count) {
      this.program_area_loader = true;
      GETPROGRAMS(count)
        .then((data) => {
          this.total = data.data.count;
          this.programs = data.data.results;
        })
        .catch(() => {
          this.programs = [];
        })
        .finally(() => {
          this.program_area_loader = false;
        });
    },
  },
  mounted() {
    this.getPrograms();
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  width: 150px;
}
label {
  font-size: 0.9375rem;
}

* {
  font-family: 'Open Sans';
}
label {
  font-size: 0.813rem !important;
}
.form-check-input {
  font-family: 'Open Sans' !important;
}
</style>
