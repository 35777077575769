<template>
  <div>
    <strong>
      <p>Please drop your enquiries, We would get back to you as soon as possible</p>
    </strong>
    <p v-show="successMessage" class="alert alert-success rounded">
      Thank you for your enquiry. We will keep you informed about the latest updates to the
      Platform.
    </p>
    <p v-show="errorMessage" class="alert alert-danger rounded">
      An error occured, please try again later.
    </p>
    <p v-show="noFields" class="alert alert-warning">Please fill in all fields</p>
    <div>
      <span>
        <base-select
          type="text"
          required
          v-model="name_prefix"
          width="90px"
          selected=""
          :items="items"
          label="Prefix"
        >
        </base-select>
      </span>
      <span>
        <base-input type="text" v-model="enquiryFormFields.first_name" label="First Name" />
      </span>

      <span>
        <base-input type="text" v-model="enquiryFormFields.last_name" label="Last Name" />
      </span>
    </div>

    <div>
      <base-input
        type="email"
        label="Email"
        rules="required|email"
        name="email"
        required
        v-model="enquiryFormFields.email"
      />
    </div>

    <div>
      <base-input
        type="number"
        label="Phone Number"
        rules="required"
        name="Phone number"
        v-model="enquiryFormFields.phone_number"
      />
    </div>
    <div>
      <base-input
        type="text"
        label="Occupation/Profession"
        rules="required"
        name="occupation"
        v-model="enquiryFormFields.occupation"
      />
    </div>
    <div>
      <base-input
        type="integer"
        label="Organization"
        rules="required"
        name="organisation"
        v-model="enquiryFormFields.organization"
      />
    </div>

    <div>
      <base-textarea
        type="integer"
        label="Enquiry"
        rules="required"
        name="enquiry"
        v-model="enquiryFormFields.enquiry"
      />
    </div>
    <div class="d-flex flex-row mt-n2">
      <div class="mt-n2 pr-0"></div>
      <div>
        <div class="mt-3 mb-1">
          <p>For further enquries please contact us:</p>

          <p class="font-weight-normal d-flex mb-0">
            <span><i class="fas fa-phone-alt"></i></span>
            <span
              >Mr. Micheal Abbah - Health Systems Strengthening (HSS) Division <br />
              <span class="text-primary">(+2348095670062 , +234805635505)</span>
            </span>
          </p>
        </div>
        <!-- <div class="mt-n3 mb-1">
          <p class="font-weight-normal d-flex mb-0">
            <span><i class="fas fa-phone-alt"></i></span>
            <span
              >Dr. Ugo Omede - International Cooperation (IC) Division <br />
              <span class="text-primary">(+2348126157965) </span>
            </span>
          </p>
        </div> -->
        <div class="mt-n3 mb-1">
          <p class="font-weight-normal d-flex">
            <span><i class="fas fa-phone-alt"></i></span>
            <span
              >Mr. Martins Otuama - Monitoring and Evaluation (M&E) Division <br />
              <span class="text-primary"> (+2347031992732) </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <button @click="conformSend" class="send send--close red--text w-25">
      SUBMIT
      <!-- <v-icon style="color: white;" right>send</v-icon> -->
    </button>
    <i class="bi bi-envelope-fill"></i>
  </div>
</template>

<script>
// import axios from '@/plugin/axios';
import messageMixin from '../../mixins/messages';
// import { postEnquiry as Enquiry } from '../../services/service';
import { postEnquiry as enquiry } from '../../services/service';

export default {
  mixins: [messageMixin],
  data() {
    return {
      name_prefix: '',
      // first_name_: '',

      enquiryFormFields: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        enquiry: '',
        occupation: '',
        organization: '',
      },

      items: ['Mr ', 'Mrs ', 'Miss ', 'Ms ', 'Dr ', 'Prof ', 'Rev '],
    };
  },
  methods: {
    emptyfields() {
      this.name_prefix = '';
      this.enquiryFormFields.first_name = '';
      this.enquiryFormFields.last_name = '';
      this.enquiryFormFields.email = '';
      this.enquiryFormFields.number = '';
      this.enquiryFormFields.occupation = '';
      this.enquiryFormFields.organization = '';
      this.enquiryFormFields.enquiry = '';
    },

    conformSend() {
      // no need to add a prefix tot the name as it can cause problems when trying to fetch

      if (
        this.enquiryFormFields.first_name &&
        this.enquiryFormFields.email &&
        this.enquiryFormFields.enquiry
      ) {
        this.errorMessage = false;
        console.log('About to post');
        const payload = this.enquiryFormFields;
        enquiry(payload)
          .then((response) => {
            console.log('posting');
            // give successs maessage and empty all input fields
            if (response.status === 201) {
              this.toast();
              console.log('posted');
              this.successMessage = true;
              this.emptyfields();
            } else {
              this.errorMessage = true;
            }
          })
          .catch((e) => {
            console.log(e);
            this.errorMessage = true;
          });
        // console.log("testing form");
      } else {
        console.log('something went wrong');
        this.noFields = true;
      }
    },
  },
};
</script>

<style scoped src="./feedback.css"></style>
