<template>
  <b-container>
    <div>
      <ProjectHeroBanner
        :image="require('@/assets/dataset/images/healthdata.png')"
        heading="Nigeria’s Health Data Inventory"
        buttonOneLink="/dataset/dataset-inventory"
        buttonTwoLink="/dataset/submit-dataset"
        buttonOneText="Explore Datasets"
        buttonTwoText="Submit Dataset"
      />
      <div class="mt-5">
        <p class="text-center font-weight-bold h5">
          About Health Project Inventory
        </p>
        <p class="text-left pt-3">
          The Department of Health Planning, Research and Statistics (DPRS) of the Federal Ministry
          of Health has developed this web based inventory containing information of previous and
          ongoing health related data collection activities carried out in the country over the last
          ten years. This will help create clear data flow channels between stakeholders in the
          health sector, avoid wastage of resources by enhancing coordination between stakeholders,
          and reduce duplication in data collection.
        </p>
        <div class="text-left">
          <b-link v-if="!seemore" class="text-left text-decoration-none" @click="seemore = true">
            <i class="fas fa-chevron-down text-primary mr-1"></i>
            See more</b-link
          >
          <b-link v-else class="text-left text-decoration-none" @click="seemore = false">
            <i class="fas fa-chevron-up text-primary mr-1"></i>
            See less</b-link
          >
        </div>
        <div v-if="seemore">
          <p class="text-center font-weight-bold h5 mt-5">
            Introduction
          </p>
          <p class="text-left pt-3">
            Numerous data collection activities are carried out by organizations to collect health
            data in the country. However, these data collected are not integrated and synthesized at
            the national level. This has led to a lack of a holistic view of the HIS resources and
            data portfolios across the country. This emphasizes the need to strengthen the
            consolidation and coordination of health information system development and management,
            thereby ensuring strategic alignment of all partners and stakeholders, and improving the
            quality of and access to health data needed for informed decision making nationally.
            <br />
            <br />
            In response to this need, the M&E Division of the Department of Health Planning,
            Research and Statistics (DPRS) has developed this web based inventory of all existing
            and ongoing health related data collection activities carried out in the country over
            the last ten years. The web inventory will also help create clear data flow channels
            between stakeholders in the health sector, avoid wastage of resources by enhancing
            coordination between stakeholders, and reduce duplication in data collection.
          </p>
          <p class="text-center font-weight-bold h5 mt-5">
            Objectives
          </p>
          <p class="text-left pt-3">
            The objectives of this web based inventory are;
          </p>
          <ol class="ml-n4">
            <li class="text-left">
              To provide the M&E division with an overview of all the HIS/data related activities in
              the country.
            </li>
            <li class="text-left">
              To provide the M&E division and key decision makers with the ability to assess and
              detect information gaps after comprehensive analysis, and to support decision-making
              around filling those data gaps.
            </li>
            <li class="text-left">
              To provide easy access to data and documentation in a format most convenient for
              stakeholders and the general public.
            </li>
            <li class="text-left">
              To enable judicious use of existing resources – this mapping will support resource
              allocation and ensure that new programs and projects can focus on new areas, and build
              on existing findings to enhance decision making, and support new project goal/outcome
              definition.
            </li>
          </ol>
          <p class="text-center font-weight-bold h5 mt-5">
            Mandate
          </p>
          <p class="text-left pt-3">
            This project is in fulfillment of the National Health Act Section 35, subsection 2 which
            states that
            <br />
            <br />“The Minister may, for the purpose of creating, maintaining or adapting databases
            within the national health information system desired in subsection (1) of this section,
            prescribe categories or kinds of data for submission and collection and the manner and
            format in which and by whom the data is to be compiled or collated and shall be
            submitted to the Federal Ministry of Health”.
            <br />
            <br />
            Furthermore, The DPRS is mandated to develop a national health data warehouse serving as
            central repository of integrated health data sources that is up-to-date and accessible
            to all, as contained in the National HIS policy which states the following:
          </p>
          <ul class="dash">
            <li class="text-left">
              All in-country health development partners and the private sector shall support the
              collection of data contained within the National Indicator Reference Document.
            </li>
            <li class="text-left">
              All non-routine health indicators collected by coordinating agencies shall be
              integrated into repository of the health information system.
            </li>
            <li class="text-left">
              A national health data warehouse serving as central repository of integrated health
              data sources that is up-to-date and accessible to all shall be domiciled and managed
              by the Department of Health Planning, Research and Statistics, FMoH.
            </li>
            <li class="text-left">
              All health and health related survey datasets and reports shall be warehoused with the
              FMoH within one year of completion of the survey (FMoH, NHIS Policy, pg 14)
            </li>
          </ul>
          <p class="text-center font-weight-bold h5 mt-5">
            Data sources
          </p>
          <p class="text-left pt-3">
            Information about datasets used to populate this website comes from organizations that
            have collected or are collecting the data. Information about older surveys was collected
            from the National Bureau of Statistics website. Information from the following data
            sources will be used to populate the website:
            <br />
          </p>
          <ul class="dash">
            <li class="text-left">
              Surveys and mappings conducted by the Federal Ministry of Health and other
              organizations
            </li>
            <li class="text-left">
              Routine Health Information System (DHIS2) of the Federal Ministry of Health
            </li>
            <li class="text-left">
              Health information systems of other organizations
            </li>
          </ul>
          <p class="text-center font-weight-bold h5 mt-5">
            About Micro-data access
          </p>
          <p class="text-left pt-3">
            Micro-data (raw data) refers to the characteristics of a population such as individuals,
            households, facilities, schools or other establishments collected by a survey, census,
            or any other data collection activity.
            <br />
            <br />
            Micro-data access is categorized into two groups, according to the sensitivity of their
            content, disclosure risks and permission provided by organizations.
            <br />
          </p>
          <ul class="dash">
            <li class="text-left">
              Publicly accessible micro-data: The micro-data is accessible for download directly,
              and with a simple registration process. Users will be asked to complete a short form,
              and agree to the terms and conditions of data use before they are provided with the
              data.
            </li>
            <li class="text-left">
              Licensed micro-data: The micro-data is made available after a signed agreement between
              the Department of Health Planning, Research and Statistics of Federal Ministry of
              Health, and the external user. Users have to fill a form to demonstrate the need to
              access the micro-data for statistical or research purposes, and agree to comply to the
              terms and conditions of data use, before they are provided with the data.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import ProjectHeroBanner from '../../components/Layout/ProjectHeroBanner.vue';

export default {
  components: {
    ProjectHeroBanner,
  },
  data() {
    return {
      seemore: false,
      sections: [
        {
          title: 'Research Knowledge and Management',
          description:
            'Coordinate and promote all health research for efficient health system development and knowledge sharing',
          link: '/dataset/stakeholder',
        },
        {
          title: 'Policy and Planning',
          description:
            'Coordinate health policy and plan formulation, review and priority settings',
          link: '/',
        },
        {
          title: 'International Cooperation',
          description:
            'Coordinate international cooperation on health and collaborations with development partners, NGOs and other health actor',
          link: '/',
        },
        {
          title: 'Management and Evaluation Division',
          description:
            'Coordinate monitoring and evaluation of the health system and its performance',
          link: '/',
        },
        {
          title: 'Health System Strengthening',
          description: 'Plan for human resource for health development',
          link: '/',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
ul.dash {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}
ul.dash > li:before {
  display: inline-block;
  content: '-';
  width: 1em;
  margin-left: -1em;
}
</style>
