<template>
  <b-container>
    <div>
      <!-- <b-row> -->
      <h4 class="mx-auto mb-4">Register</h4>
      <p class="text-left">
        <span class="text-left"
          >Before you can download datasets, you must register as a PDM data user. Dataset access is
          only granted for legitimate research popurses. Please
          <b-link to="/dataset/login" class="text-decoration-none">login here</b-link> if you
          already have an approved account.</span
        >
      </p>
      <!-- </b-row> -->
      <b-alert :show="showAlert"
        >Your Account has been created successfully please check your email for the activation
        link</b-alert
      >
      <ValidationObserver v-slot="{ passes }" class="pb-5">
        <form @submit.prevent="passes(onSubmit)">
          <b-row class="p-0">
            <b-col cols="12" md="6" lg="6">
              <base-input
                height="3rem"
                placeholder="First Name"
                width="100%"
                rules="required"
                name="First Name"
                v-model="user.first_name"
              />
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <base-input
                height="3rem"
                placeholder="Last Name"
                width="100%"
                rules="required"
                name="Last Name"
                v-model="user.last_name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" lg="6">
              <base-input
                height="3rem"
                placeholder="Institution"
                width="100%"
                rules="required"
                name="Institution"
                v-model="user.institution"
              />
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <base-select
                height="3rem"
                width="100%"
                :items="organization_type"
                name="Institution type"
                selected="Institution type"
                rules="required"
                v-model="user.institution_type"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" lg="6">
              <base-select
                height="3rem"
                width="100%"
                :items="['Nigeria']"
                selected="Country"
                name="Country"
                rules="required"
                v-model="user.country"
              />
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <base-select
                height="3rem"
                width="100%"
                :items="states"
                selected="State"
                name="State"
                rules="required"
                v-model="user.state"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" lg="6">
              <base-input
                height="3rem"
                placeholder="Phone Number"
                rules="required"
                width="100%"
                name="Phone Number"
                v-model="user.phone"
              />
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <base-input
                height="3rem"
                placeholder="Email"
                width="100%"
                rules="required"
                name="Email"
                v-model="user.email"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" lg="6">
              <base-input
                height="3rem"
                placeholder="Password"
                width="100%"
                rules="required"
                type="password"
                name="Password"
                v-model="user.password"
              />
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <base-input
                height="3rem"
                placeholder="Confirm password"
                width="100%"
                type="password"
                rules=""
                name="Confirm password"
                v-model="user.confirm_password"
              />
            </b-col>
          </b-row>
          <div class="mx-auto mt-4">
            <b-col cols="6" class="mx-auto">
              <base-checkbox
                link="/dataset/terms"
                checkboxItem="You have accepted the conditions of use for The PDM Program datasets"
              />
            </b-col>
            <b-col cols="5" class="mx-auto">
              <button type="submit" class="sharp" :disabled="loading">
                <b-spinner v-show="loading" small></b-spinner>
                Register
              </button>
              <div class="mt-4 text-center">
                <span class="mr-1">Already have an account?</span>
                <b-link class="text-decoration-none" to="/auth/login">Log in</b-link>
              </div>
            </b-col>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      states: [
        'FCT',
        'Abia',
        'Adamawa',
        'Akwa Ibom',
        'Anambra',
        'Bauchi',
        'Bayelsa',
        'Benue',
        'Borno',
        'Cross River',
        'Delta',
        'Ebonyi',
        'Edo',
        'Ekiti',
        'Enugu',
        'Gombe',
        'Imo',
        'Jigawa',
        'Kaduna',
        'Kano',
        'Katsina',
        'Kebbi',
        'Kogi',
        'Kwara',
        'Lagos',
        'Nasarawa',
        'Niger',
        'Ogun',
        'Ondo',
        'Osun',
        'Oyo',
        'Plateau',
        'Rivers',
        'Sokoto',
        'Taraba',
        'Yobe',
        'Zamfara',
      ],
      user: {},
      showAlert: false,
      loading: false,
      organization_type: [
        'Governmental organization',
        'Individual researcher',
        'NGO',
        'Partner agency',
        'University',
        'Other',
      ],
    };
  },
  computed: {},
  methods: {
    ...mapActions('auth', [
      'LOGIN',
      'REGISTRATION',
      'GET_USER', // -> this.LOGIN()
    ]),
    async onSubmit() {
      this.loading = true;
      try {
        const { status } = await this.REGISTRATION(this.user);
        if (status === 201) {
          this.showAlert = true;
          this.$toast.show({
            type: 'INFO',
            message: `Your Account has been created successfully please check your email for the activation
        link`,
          });
          this.user = {};
          setTimeout(() => {
            this.$router.push('/auth/login');
          }, 3000);
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
  mounted() {},
};
</script>

<style scoped>
button {
  display: inline-block;
  /* padding: 10px 29px; */
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  /* vertical-align: middle; */
  width: 100%;
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /* padding: 10px 16px; */
  outline: none;
}

.sharp {
  /* width: 12em; */
  color: #fff;
  background-color: #30ab4c;
  border-color: #0b7022;
}

.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open .dropdown-toggle.sharp {
  /* border-radius: 0; */
  color: #fff;
  background-color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

/***********************
  SHARP BUTTONS
************************/
.sharp {
  outline: none;
  /* border-radius: 0; */
}

/***********************
  CUSTON BTN VALUES
************************/

button {
  padding: 0.7rem;
  outline: none;
  border: 0 none;
  /* font-weight: 700; */
  letter-spacing: 1px;
}
button:focus,
button:active:focus,
button.active:focus {
  outline: 0 none;
}

.sharp {
  background: #30ab4c;
  color: #ffffff;
}
.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open > .dropdown-toggle.sharp {
  background: #30ab4c;
}
.sharp:active,
.sharp.active {
  background: #45cc64;
  box-shadow: none;
}
.save:active,
.save.active {
  background: #d8f7e0;
  color: #30ab4c;
  box-shadow: none;
}
</style>
