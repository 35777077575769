<template>
  <div id="about" class="container">
    <h4 class="text-primary font-weight-normal my-4 custom">About</h4>
    <p class="text-justify">
      This central web-based inventory has been developed by the Department of Health Planning,
      Research and Statistics (DHPRS) of the Federal Ministry of Health (FMoH) to compile all health
      data and intervention projects carried out by international partners & non-governmental
      organisations across all health domains in the country. The platform also captures details of
      Non Governmental Organizations (NGOs) that have been registered with the Federal Ministry of
      Health under the purview of the NGO branch at the Health Systems Strengthening division of the
      DHPRS-FMoH.
    </p>
    <p class="text-justify">
      This information will be used to enhance coordination of all health data activities and
      projects, and increase visibility of these activities and resources to the general public.
      This website contains inventories of all health related data activities, health partner
      projects and in the country.
    </p>
    <p class="text-justify">
      Section 3.2 (Page 9) of the 2014 National HIS Policy states that; “All stakeholders including
      bilateral and multilateral agencies* and other international development agencies/programmes
      working at national and subnational levels shall support HIS activities through existing
      government structures”
    </p>
    <p class="text-justify">
      *A Bilateral organization is a government agency or non-profit organization based in a single
      country that provides aid to people in other countries. <br />
      *A multilateral organization is formed by three or more nations to work on issues that are
      relevant to each of them.
    </p>
    <p class="text-justify">
      “Performance of programmes/projects supported by international development organizations shall
      be assessed using the data obtainable from the approved national data collection system”.
    </p>
    <p class="text-justify">This inventory has been divided into four sections;</p>
    <ul class="ml-5">
      <li class="text-justify">
        <b>The Health Data inventory:</b> An inventory of all health related data activities,
        datasets, and data portfolios in the country. Visit the “About Data” page to learn more
        about this inventory
      </li>
      <li class="text-justify">
        <b>The Health Projects inventory:</b> An inventory of health partners and their projects in
        the country. Visit the “About Projects” page to learn more about this inventory
      </li>
      <li class="text-justify">
        <b>The NGO Section:</b> An inventory of all registered Nigerian NGOs, their contact
        information and their activity reports. To learn more about it, click here.
      </li>
      <li class="text-justify">
        <b>The Stakeholder Database:</b> A comprehensive record of all stakeholders’ information and
        activities/areas of support to the DHPRS. Tell me more about the stakeholder database.
      </li>
    </ul>
    <div class="my-5">
      <h4 class="custom"><u>The NGO Section</u></h4>
      <p class="text-justify">
        Non-Governmental Organisations (NGOs) branch of the Health System Strengthening division of
        the Department of Health Planning, Research and Statistics was created by the Federal
        Ministry of Health to coordinate the activities of health sector NGOs nationwide. This
        resource will serve as a database of all registered health NGOs across the country and would
        also provide the Ministry and stakeholders with necessary information on NGOs and their
        focus areas, to aid the proper coordination of the activities of NGOs in the country.
      </p>

      <p>The objectives are:</p>
      <ul class="text-justify ml-5">
        <li>
          To recognize all NGOs actively carrying out interventions in the health space.
        </li>
        <li>
          To provide the Ministry and stakeholders with information about all NGOs working in the
          health sector for better coordination of health interventions in the country.
        </li>
        <li>
          To disseminate information on priorities of the health sector thus providing needed
          guidance to NGOs on health programs/interventions.
        </li>
      </ul>
    </div>
    <div class="my-5">
      <h4 class="custom"><u>Stakeholder Database</u></h4>
      <p class="text-justify">
        The Stakeholder Database collates stakeholders information to manage stakeholder engagements
        and activities. This will strengthen stakeholder management within the FMoH.
      </p>

      <p class="text-justify">
        Note that, stakeholders do not solely refer to organizations or partners but every entity
        that is involved in carrying out an activity or set of activities within each branch of a
        division within the DHPRS.
      </p>

      <p>The objectives are:</p>
      <ul class="text-justify ml-5">
        <li>
          To provide the DHPRS with a continuously updated database of the FMoH’s stakeholders and
          their areas of support.
        </li>
        <li>
          To ensure all stakeholder activities are aligned towards the Ministry’s strategic goals.
        </li>
        <li>
          To ensure the DHPRS effectively and efficiently engages all stakeholders using the
          appropriate platforms.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about',
};
</script>

<style scoped>
h4 {
  color: black;
}
.custom {
  font-family: 'Open Sans';
}

p {
  font-family: 'Roboto';
  font-size: 1.125rem;
  letter-spacing: 0px;
  word-spacing: 0px;
  font-weight: normal;
  color: black;
}

li {
  font-family: 'Roboto';
  font-size: 1.125rem;
  letter-spacing: 0px;
  word-spacing: 0px;
  font-weight: normal;
  color: black;
}
</style>
