import Vue from 'vue';
import VueRouter from 'vue-router';
import { router as DATASET_ROUTE } from '../modules/dataset';
import { router as NGO_ROUTE } from '../modules/ngo';
import { router as AUTH_ROUTE } from '../modules/auth';
import FEEDBACK_ROUTE from '../modules/feedback';
import INNOVATION_MAPPING from '../modules/inventory-dashboard';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   // redirect: '/ngo',
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
  ...DATASET_ROUTE,
  ...NGO_ROUTE,
  ...AUTH_ROUTE.routes,
  ...FEEDBACK_ROUTE,
  ...INNOVATION_MAPPING,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  /**
   * This will simply make the page scroll to top for all route navigations.
     Returning the savedPosition will result in a native-like
     behavior when navigating with back/forward buttons
   * @param {import('vue-router').Route} to
   * @param {import('vue-router').Route} from
   * @param {import('vue-router/types/router').Position} savedPosition
   */
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
      // eslint-disable-next-line no-else-return
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(AUTH_ROUTE.routerGuard);

export default router;
