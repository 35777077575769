<template>
  <b-container>
    <div>
      <b-row class="mb-5 ml-0" align-h="between">
        <b-col cols="12" sm="4" class="mt-4 ml-n3">
          <h4 class="text-left text-primary">Explore Datasets</h4>
        </b-col>
        <b-col
          cols="12"
          sm="3"
          class="text-right mt-n1 mr-n2 ml-n3 ml-sm-auto pl-4 justify-content-end d-flex"
        >
          <base-input
            prepend="fa fa-search"
            prependclass="mt-1"
            :filled="true"
            placeholder="Search"
            width="100%"
          />
        </b-col>
      </b-row>
      <b-row class="p-0" no-gutters align-h="between">
        <b-col cols="12" sm="9">
          <div v-if="loading" class="text-center loading-icon">
            <b-spinner v-if="loading" x-large></b-spinner>
          </div>
          <div v-else>
            <ProjectCard
              v-for="(project, index) in projects.results"
              :key="index"
              :to="`/dataset/view/${project.id}`"
              :seemorelink="true"
              :date="project.created_at"
            >
              <template slot="body">
                <b-card-title class="text-left font-weight-bold text-black-50 h5">
                  {{ project.name }}
                </b-card-title>
                <b-card-text class="text-left text-black-50">
                  Capacity building for Local Governments and States’ Ministries, Departments and
                  Agencies (MDAs) responsible for rural water supply and sanitation intervention.
                </b-card-text>

                <div class="text-left mt-4">
                  <span class="font-weight-bolder text-black-50">Period of Implementation:</span>
                  <span class="ml-1 text-black-50"
                    >{{ getYear(project.start_date) }} - {{ getYear(project.end_date) }}</span
                  >
                </div>

                <div class="text-left mt-4">
                  <span class="font-weight-bolder text-black-50">Programs Area:</span>
                  <span
                    v-for="(program, index) in project.program_area"
                    :key="index"
                    class="ml-1 text-primary text-decoration-none"
                    ><span v-if="index !== project.program_area.length - 1"
                      >{{ program.name }},</span
                    >
                    <span v-else>{{ program.name }}</span></span
                  >
                </div>
              </template>
            </ProjectCard>
            <div class="mx-auto text-center">
              <Pagination
                :totalPages="projects.count"
                :itemCount="projects.results.length"
                @change-page="changePage($event)"
                :currentPage="currentPage"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="3" class="pl-5 d-none d-sm-block">
          <FilterSideBar />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import moment from 'moment';
import ProjectCard from '../../components/Shared/ProjectCard.vue';
import Pagination from '../../components/Shared/Pagination.vue';
import FilterSideBar from '../../components/Shared/FilterSideBar.vue';

export default {
  components: {
    ProjectCard,
    Pagination,
    FilterSideBar,
  },
  data() {
    return {
      currentPage: 1,
      loading: false,
      projects: { results: [] },
    };
  },
  async mounted() {
    this.loading = true;
    try {
      const projects = await this.$dataset.getDatasets();
      this.projects = projects.data;
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
  methods: {
    async changePage(e) {
      this.loading = true;
      try {
        const projects = await this.$dataset.getDatasets(e);
        this.projects = projects.data;
        this.currentPage = e;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    getYear(date) {
      return moment(date).format('YYYY');
    },
  },
};
</script>
